import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

function HomageCondDetail() {
  //Color Scheme
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  return (
    <>
      <Helmet>
        <body className="section-bg" />
      </Helmet>
      <Container fluid className="fonts-contain">
        <>
          <Container>
            <Helmet>
              <title>{`Arbuckle - GarageFonts`}</title>
            </Helmet>
            <FadeIn>
              <Row>
                <Col md={8}>
                  <p className="detail-name">Homage Condensed</p>
                  <p className="desc-status">
                    Paying homage to the 1960s and 1970s designs of Tom Carnase
                    and Herb Lubalin, Homage Condensed is a digital revival and
                    recutting of LSC Condensed in two weights along with italics
                    and numerous stylistic alternate characters. It was designed
                    by International Typefounders and Phil's Fonts and
                    originally released in 2016. Great for large, condensed
                    headlines, it is elegant but also adds a retro flavor due to
                    its 1970s roots and classic ad agency stylings. Homage
                    Condensed's character set features a large array of
                    alternates and OT features that make it a blast to use when
                    executing bold and dynamic headlines and social media
                    stories.
                  </p>
                  <p className="desc-status">
                    <a href="/font/homage-condensed">
                      Type tester and specimens
                    </a>
                  </p>
                  <p className="desc-status">
                    <a href="/details">Back to details</a>
                  </p>
                </Col>
              </Row>
            </FadeIn>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5">
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-condensed/homage-c-story-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/homage-condensed/homage-c-story-3.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-condensed/homage-c-story-7.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-condensed/homage-c-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/homage-condensed/homage-c-story-5.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-condensed/homage-c-story-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </>
      </Container>
    </>
  );
}

export default HomageCondDetail;
