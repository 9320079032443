import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";

function AdobeFonts() {
  // Footer
  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain";
  }, []);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>FontBakery Reports</title>
          <meta property="og:title" content="Foundry - GarageFonts" />
        </Helmet>
        <Row>
          <Col lg={7} className="mt-4">
            <p>2024-0118</p>
            <p>Fuqua:</p>
            <p>
              <a href="https://garagefonts.com/fontbakery/adobefonts/_fbakery-adobefonts-otf-fuqua.html">
                fbakery-adobefonts-otf-fuqua.html
              </a>
              <br />
              <a href="https://garagefonts.com/fontbakery/adobefonts/_fbakery-universal-otf-fuqua.html">
                fbakery-universal-otf-fuqua.html
              </a>
            </p>
            <p>Fuqua Variable:</p>
            <p>
              <a href="https://garagefonts.com/fontbakery/adobefonts/_fbakery-adobefonts-ttf-fuqua-var.html">
                fbakery-adobefonts-ttf-fuqua-var.html
              </a>
              <br />
              <a href="https://garagefonts.com/fontbakery/adobefonts/_fbakery-universal-ttf-fuqua-var.html">
                fbakery-universal-ttf-fuqua-var.html
              </a>
            </p>
            <p>Adore Mono:</p>
            <p>
              <a href="https://garagefonts.com/fontbakery/adobefonts/_fbakery-adobefonts-otf-adore-mono.html">
                fbakery-adobefonts-otf-adore-mono.html
              </a>
              <br />
              <a href="https://garagefonts.com/fontbakery/adobefonts/_fbakery-universal-otf-adore-mono.html">
                fbakery-universal-otf-adore-mono.html
              </a>
            </p>
            <p>Arbuckle:</p>
            <p>
              <a href="https://garagefonts.com/fontbakery/adobefonts/_fbakery-adobefonts-otf-arbuckle.html">
                fbakery-adobefonts-otf-arbuckle.html
              </a>
              <br />
              <a href="https://garagefonts.com/fontbakery/adobefonts/_fbakery-universal-otf-arbuckle.html">
                fbakery-universal-otf-arbuckle.html
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdobeFonts;
