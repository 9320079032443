import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { Spotify } from "react-spotify-embed";

function Blog0002() {
  // Footer
  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain";
  }, []);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Row>
          <Col md={12}>
            <h1 className="header">Font Tiers</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <p>04/18/2023</p>
            <p>
              One of the great and unique aspects of the GarageFonts font
              catalog is that it has always been eclectic and experimental. For
              instance, eccentric grunge fonts can appear along side reliable
              sans serif typefaces. We’ve designated two “font tiers” as an
              organizational tool as newer and older designs emerge and
              re-emerge in the catalog. <Link to="/fonts">View the fonts</Link>
            </p>
            <p>---------</p>
            <p>Double Nickles:</p>
            <p>
              <Link to="/fonts#double-nickels">Double Nickel fonts</Link>{" "}
              feature extended character sets that are &#8805; the{" "}
              <a
                href="https://adobe-type-tools.github.io/adobe-latin-charsets/adobe-latin-3.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Adobe Latin 3
              </a>{" "}
              specification and contain more design precision, in terms of
              spacing, kerning, and/or features. The name references the
              Minutemen, a band from San Pedro, California who played a brand of
              punk rock that was multifaceted and precise.
            </p>
            <Spotify
              wide
              link="https://open.spotify.com/track/0jwiakOoVQkolSUVTnehVs?si=115a10195d5744a9"
            />
            <p>---------</p>
            <p>12XUs:</p>
            <p>
              <Link to="/fonts#12xus">12XU fonts</Link> feature basic character
              sets that are &#8804; the{" "}
              <a
                href="https://adobe-type-tools.github.io/adobe-latin-charsets/adobe-latin-1.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Adobe Latin 1
              </a>{" "}
              specification and contain more design experimentation, in terms of
              outlines, spacing, kerning, and/or features. The name is in
              reference to Wire, a band from London, England who played a brand
              of punk rock that was sparse and edgy.
            </p>
            <Spotify
              wide
              link="https://open.spotify.com/track/2X7Yq4DYQmEx1bZ1bYjlYx?si=839b88f92cb242f5"
            />
            <p>---------</p>
            <p>—Jon</p>
            <p>---------</p>
            <p>
              <Link to="/blog">Back to GF Blog</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Blog0002;
