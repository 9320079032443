import { Container, Row, Col } from "react-bootstrap";

function Progress({ font }) {
  return (
    <>
      {/* Calc Select */}
      <Container id="buy" className="fonts-license">
        <Row>
          <Col md={12}>
            <Col md={12}>
              <p className="font-section">Buy {font.name}</p>
            </Col>
            <p className="pt-3">
              To purchase licensing for {font.name},{" "}
              <a href="/contact">please contact us directly</a>.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Progress;
