export const CART_ADD_FONT = "CART_ADD_FONT";
export const CART_ADD_PART = "CART_ADD_PART";
export const CART_ADD_TRIAL = "CART_ADD_TRIAL";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_REMOVE_ALL = "CART_REMOVE_ALL";
export const CART_CLEAR_ITEMS = "CART_CLEAR_ITEMS";
export const CART_DISCOUNT = "CART_DISCOUNT";
export const CART_DISCOUNT_RESET = "CART_DISCOUNT_RESET";
export const CART_VOUCHER_VALID_REQUEST = "CART_VOUCHER_VALID_REQUEST";
export const CART_VOUCHER_VALID_SUCCESS = "CART_VOUCHER_VALID_SUCCESS";
export const CART_VOUCHER_VALID_RESET = "CART_VOUCHER_VALID_RESET";
export const CART_TAX = "CART_TAX";
export const CART_TAX_RESET = "CART_TAX_RESET";
export const CART_VAT = "CART_VAT";
export const CART_VAT_RESET = "CART_VAT_RESET";
export const CART_COUNTRY = "CART_COUNTRY";
export const CART_COUNTRY_RESET = "CART_COUNTRY_RESET";
export const CART_USA_STATE = "CART_USA_STATE";
export const CART_USA_STATE_RESET = "CART_USA_STATE_RESET";
export const CART_BILLING_INFO = "CART_BILLING_INFO";
export const CART_BILLING_INFO_RESET = "CART_BILLING_INFO_RESET";
export const CART_LICENSE_HOLDER = "CART_LICENSE_HOLDER";
export const CART_LICENSE_HOLDER_RESET = "CART_LICENSE_HOLDER_RESET";
export const CART_PAYMENT_METHOD = "CART_PAYMENT_METHOD";
export const CART_PAYMENT_METHOD_RESET = "CART_PAYMENT_METHOD_RESET";
