import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { submitReset } from "../../actions/userActions";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ResetSubmit() {
  // Selectors
  const userResetSubmit = useSelector((state) => state.userResetSubmit);
  const { error, loading, success } = userResetSubmit;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [email, setEmail] = useState("");
  const location = useLocation();
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/reset-sent";

  // useEffects
  useEffect(() => {
    if (success) {
      navigate(redirect, { replace: true });
    }
  }, [navigate, success, redirect]);

  // Submit
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(submitReset(email));
  };

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      {loading ? (
        <Loader />
      ) : (
        <>
          {error && (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="danger">{error}</Message>
              </Col>
            </Row>
          )}
          <Form onSubmit={submitHandler}>
            <Row>
              <Col md={12}>
                <h1 className="header">Reset Password</h1>
              </Col>
              <Col md={12}>
                <p>Please enter your email to reset your password.</p>
              </Col>
              <Col className="mt-3" lg={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="form-link">
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Container>
  );
}

export default ResetSubmit;
