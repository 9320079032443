import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";

function PipeSquare() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top pipe-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top pipe-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain pipe-bg";
  }, []);

  return (
    <Container fluid className="square-contain">
      <Helmet>
        <body className="pipe-bg" />
      </Helmet>
      <>
        <Row>
          <Col md={6}>
            <div className="image-square">
              {/* <a href="/font/fuqua"> */}
              <img
                src="/images/pipe/fuqua/fuqua-001.svg"
                className="img-fluid"
                alt="Fuqua"
              />
              {/* </a> */}
            </div>
            <p className="name">Fuqua</p>
          </Col>
          <Col md={6}>
            <div className="image-square">
              {/* <a href="/font/clarice"> */}
              <img
                src="/images/pipe/clarice/clarice-001.svg"
                className="img-fluid"
                alt="Clarice"
              />
              {/* </a> */}
            </div>
            <p className="name">Clarice</p>
          </Col>
          <Col md={6}>
            <div className="image-square">
              {/* <a href="/font/fono"> */}
              <img
                src="/images/pipe/fono/fono-001.svg"
                className="img-fluid"
                alt="Fono"
              />
              {/* </a> */}
            </div>
            <p className="name">Fono</p>
          </Col>
          <Col md={6}>
            <div className="image-square">
              {/* <a href="/font/nice-weekend"> */}
              <img
                src="/images/pipe/nice-weekend/nice-weekend-001.svg"
                className="img-fluid"
                alt="Nice Weekend"
              />
              {/* </a> */}
            </div>
            <p className="name">Nice Weekend</p>
          </Col>
        </Row>
      </>
    </Container>
  );
}

export default PipeSquare;
