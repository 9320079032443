import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Colophon() {
  // State Etc
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());

  // useEffects
  useEffect(() => {
    getYear();
  }, []);

  // Footer
  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain";
  }, []);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>Colophon - GarageFonts</title>
          <meta property="og:title" content="Colophon - GarageFonts" />
        </Helmet>
        <Row>
          <Col md={12}>
            <h1 className="header">About</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/foundry" className="nav-link">
                  Foundry
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/colophon" className="nav-link active">
                  Colophon
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="mt-4">
            <p>
              This website was designed and developed by{" "}
              <a
                href="https://www.tiotype.com/"
                target="_blank"
                rel="noreferrer"
              >
                Tiotype
              </a>
              . Content, licensing, and UX design assistance and collaboration
              provided by{" "}
              <a
                href="https://www.caketype.com/"
                target="_blank"
                rel="noreferrer"
              >
                Pieter van Rosmalen
              </a>{" "}
              of CakeType and Bold Monday.
              <br />
              <br />
              The typeface used throughout this website is{" "}
              <a
                href="https://fonts.google.com/specimen/IBM+Plex+Mono"
                target="_blank"
                rel="noreferrer"
              >
                IBM Plex Mono
              </a>{" "}
              designed by Mike Abbink and Bold Monday.
              <br />
              <br />
              GarageFonts and other trademarks and trade names are property of
              GarageFonts LLC. This website and its content is copyright of
              GarageFonts LLC.
              <br />
              <br />
              (C) GarageFonts LLC {date}. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Colophon;
