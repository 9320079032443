import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { listClientDownload } from "../../actions/sectionActions";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ClientDownload() {
  // Selectors
  const clientDownload = useSelector((state) => state.clientDownload);
  const { error, loading, clientTrialRec } = clientDownload;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    dispatch(listClientDownload());
  }, [dispatch]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top client-trial-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top client-trial-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain client-trial-bg";
  }, []);

  return (
    <Container className="section-contain client-trial-bg">
      <Helmet>
        <body className="client-trial-bg" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : error ? (
        <Row className="message-contain">
          <Col md={12}>
            <Message variant="danger">{error}</Message>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col lg={12}>
              <h1 className="header">Thanks!</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <p>Trial fonts licensed to:</p>
              {clientTrialRec.company ? (
                <>
                  {clientTrialRec.name}
                  <br />
                  {clientTrialRec.company}
                  <br />
                  {clientTrialRec.email}
                </>
              ) : (
                <>
                  {clientTrialRec.name}
                  <br />
                  {clientTrialRec.email}
                </>
              )}
            </Col>
            <Col lg={12} className="mt-4">
              <Button className="btn buy-button" href={clientTrialRec.download}>
                Download trial fonts
              </Button>
            </Col>
            <Col lg={12} className="mt-4">
              <p>
                This download link will expire in 1 hour.{" "}
                <a href="/trial-fonts">Submit form again</a> if link expires.
              </p>
              <p className="mt-4">
                Interested in full featured trial fonts?{" "}
                <Link to="/contact">Drop us a line</Link>.
              </p>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ClientDownload;
