import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { activate } from "../../actions/userActions";
import { useParams } from "react-router-dom";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ActivateInit() {
  // Selectors
  const userActivate = useSelector((state) => state.userActivate);
  const { error, loading, userInfo } = userActivate;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const { uid, token } = useParams();
  const location = useLocation();
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/activate-complete";

  // useEffects
  useEffect(() => {
    if (userInfo) {
      navigate(redirect, { replace: true });
    }
  }, [navigate, userInfo, redirect]);

  // On Activate
  const onActivate = () => {
    dispatch(activate(uid, token));
  };

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      {loading ? (
        <Loader />
      ) : (
        <>
          {error && (
            <Row className="message-contain">
              <Col lg={12}>
                <Message variant="danger">{error}</Message>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={12}>
              <h1 className="header">Activate</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>Click below to activate your account.</Col>
            <Col lg={12} className="mt-2">
              <Button onClick={onActivate} variant="primary">
                Activate account
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ActivateInit;
