import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import { saveVoucherValid } from "../../actions/cartActions";

function VoucherExpired() {
  // Selectors
  const cartVoucherValid = useSelector((state) => state.cartVoucherValid);
  const { voucherValid } = cartVoucherValid;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  const [voucherCode, setVoucherCode] = useState(voucherValid.code);

  // On Voucher Remove
  const onVoucherRemove = () => {
    dispatch(saveVoucherValid("remove"));
  };

  // useEffects
  useEffect(() => {
    if (!voucherCode) {
      setVoucherCode("");
    }
  }, [voucherCode]);

  return (
    <>
      <Card className="cart-part-card">
        <Card.Body>
          <Row>
            <Col lg={6} className="my-auto cart-fill d-none d-lg-block">
              Voucher code has expired
            </Col>
            <Col lg={3} className="my-auto">
              Voucher code has expired
            </Col>
            <Col lg={3} className="my-auto text-end d-none d-lg-block">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => onVoucherRemove()}
              >
                Remove
              </button>
            </Col>
            <Col lg={3} className="my-auto d-lg-none">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => onVoucherRemove()}
              >
                Remove
              </button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default VoucherExpired;
