import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  CART_ADD_FONT,
  CART_ADD_PART,
  CART_ADD_TRIAL,
  CART_REMOVE_ITEM,
  CART_REMOVE_ALL,
  CART_DISCOUNT,
  CART_VOUCHER_VALID_REQUEST,
  CART_VOUCHER_VALID_SUCCESS,
  CART_TAX,
  CART_VAT,
  CART_COUNTRY,
  CART_USA_STATE,
  CART_BILLING_INFO,
  CART_LICENSE_HOLDER,
  CART_PAYMENT_METHOD,
} from "../constants/cartConstants";

export const addFont =
  (slug, price, calcName) => async (dispatch, getState) => {
    const endpoint = `${HOST}${API}`;
    const addFontUrl = `${endpoint}/font/${slug}`;
    const { data } = await axios.get(addFontUrl);

    dispatch({
      type: CART_ADD_FONT,
      payload: {
        fontId: data.font_id,
        family: data.font_id,
        prodType: "family",
        calcType: "unified",
        name: data.name,
        cartName: data.cart_name,
        slug: data.slug,
        famSlug: data.slug,
        fontClass: data.font_class,
        download: data.download,
        calcName: calcName,
        price: price,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const addPart =
  (slug, price, calcName) => async (dispatch, getState) => {
    const endpoint = `${HOST}${API}`;
    const addPartUrl = `${endpoint}/part/${slug}`;
    const { data } = await axios.get(addPartUrl);

    dispatch({
      type: CART_ADD_PART,
      payload: {
        fontId: data.part_id,
        family: data.font,
        prodType: "font",
        calcType: "unified",
        name: data.name,
        cartName: data.cart_name,
        slug: data.slug,
        famSlug: data.f_slug,
        fontClass: data.font_class,
        download: data.download,
        calcName: calcName,
        price: price,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const addTrial =
  (slug, price, calcName) => async (dispatch, getState) => {
    const endpoint = `${HOST}${API}`;
    const addTrialUrl = `${endpoint}/trial/${slug}`;
    const { data } = await axios.get(addTrialUrl);

    dispatch({
      type: CART_ADD_TRIAL,
      payload: {
        fontId: data.trial_id,
        family: data.font,
        prodType: "trial",
        calcType: "trial",
        name: data.name,
        cartName: data.cart_name,
        slug: data.slug,
        famSlug: data.f_slug,
        fontClass: data.font_class,
        download: data.download,
        calcName: calcName,
        price: price,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const removeItem = (slug) => async (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: slug,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeAll = () => async (dispatch, getState) => {
  const message = "Your cart is empty.";

  dispatch({
    type: CART_REMOVE_ALL,
    payload: message,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const saveCartDiscount = (data) => async (dispatch) => {
  dispatch({
    type: CART_DISCOUNT,
    payload: data,
  });

  localStorage.setItem("discount", JSON.stringify(data));
};

export const saveVoucherValid = (vcode) => async (dispatch) => {
  dispatch({ type: CART_VOUCHER_VALID_REQUEST });

  const endpoint = `${HOST}${API}`;
  const getVoucherUrl = `${endpoint}/cart/voucher/${vcode}/`;
  const { data } = await axios.get(getVoucherUrl);

  dispatch({
    type: CART_VOUCHER_VALID_SUCCESS,
    payload: data,
  });

  localStorage.setItem("voucherValid", JSON.stringify(data));
};

export const saveCartTax = (data) => async (dispatch) => {
  dispatch({
    type: CART_TAX,
    payload: data,
  });

  localStorage.setItem("tax", JSON.stringify(data));
};

export const saveCartVat = (data) => async (dispatch) => {
  dispatch({
    type: CART_VAT,
    payload: data,
  });

  localStorage.setItem("vat", JSON.stringify(data));
};

export const saveCountries = () => async (dispatch) => {
  const endpoint = `${HOST}${API}`;
  const countryListUrl = `${endpoint}/cart/countries/`;
  const { data } = await axios.get(countryListUrl);

  dispatch({
    type: CART_COUNTRY,
    payload: data,
  });

  localStorage.setItem("countries", JSON.stringify(data));
};

export const saveUsaStates = () => async (dispatch) => {
  const endpoint = `${HOST}${API}`;
  const usaStateListUrl = `${endpoint}/cart/states/`;
  const { data } = await axios.get(usaStateListUrl);

  dispatch({
    type: CART_USA_STATE,
    payload: data,
  });

  localStorage.setItem("usaStates", JSON.stringify(data));
};

export const saveBillingInfo = (data) => async (dispatch) => {
  dispatch({
    type: CART_BILLING_INFO,
    payload: data,
  });

  localStorage.setItem("billingInfo", JSON.stringify(data));
};

export const saveLicenseHolder = (data) => async (dispatch) => {
  dispatch({
    type: CART_LICENSE_HOLDER,
    payload: data,
  });

  localStorage.setItem("licenseHolder", JSON.stringify(data));
};

export const savePaymentMethod = (data) => async (dispatch) => {
  dispatch({
    type: CART_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};
