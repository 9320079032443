import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Contact() {
  // Footer
  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain";
  }, []);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>Contact - GarageFonts</title>
          <meta property="og:title" content="Contact - GarageFonts" />
        </Helmet>
        <Row>
          <Col md={12}>
            <h1 className="header">About</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/foundry" className="nav-link">
                  Foundry
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link active">
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/colophon" className="nav-link">
                  Colophon
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="mt-4">
            <p>
              GarageFonts
              <br />
              Pittsburgh, PA
              <br />
              United States
              <br />
              <br />
              Email:
              <br />
              <a href="mailto:email@example.com">hello@garagefonts.com</a>
              <br />
              <br />
              <a
                href="https://www.instagram.com/garagefonts/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
              <br />
              <a
                href="https://typo.social/@garagefonts"
                target="_blank"
                rel="noreferrer"
              >
                Mastodon
              </a>
              <br />
              <a
                href="https://github.com/garagefonts"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
