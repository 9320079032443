import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import { getOrderDetails } from "../../actions/orderActions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../main/Loader";
import Message from "../main/Message";

function Invoice() {
  // Selectors
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const { orderId } = useParams();

  // useEffects
  useEffect(() => {
    if (!userInfo) {
      navigate("/login", { replace: true });
    } else {
      if (!order || order.order_id !== Number(orderId)) {
        dispatch(getOrderDetails(orderId));
      }
    }
  }, [dispatch, navigate, userInfo, order, orderId]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      {loading ? (
        <Loader />
      ) : error ? (
        <Row className="message-contain">
          <Col md={12}>
            <Message variant="danger">{error}</Message>
          </Col>
        </Row>
      ) : (
        <>
          <Helmet>
            <title>Invoice-{order.order_ref}</title>
          </Helmet>
          <div className="invoice-header">
            <Row>
              <Col md={12}>
                <div className="invoice">
                  <h1 className="header">Invoice # {order.order_ref}</h1>
                  <p>{order.ux_date}</p>
                </div>
              </Col>
            </Row>
            {order.method !== "Trial" ? (
              <Row>
                <Col md={4}>
                  <p className="subhead invoice-list">Ordered by</p>
                  <p className="invoice-list">{order.user.name}</p>
                  <p>{order.user.email}</p>
                </Col>
                <Col md={4}>
                  <p className="subhead invoice-list">Billed to</p>
                  <p className="invoice-list">{order.billingInfo.name}</p>
                  {order.billingInfo.company && (
                    <p className="invoice-list">{order.billingInfo.company}</p>
                  )}
                  <p className="invoice-list">
                    {order.billingInfo.address_one}
                  </p>
                  {order.billingInfo.address_two && (
                    <p className="invoice-list">
                      {order.billingInfo.address_two}
                    </p>
                  )}
                  <p className="invoice-list">
                    {order.billingInfo.city}, {order.billingInfo.state_province}
                  </p>
                  <p className="invoice-list">
                    {order.billingInfo.zip_postal_code}
                  </p>
                  <p>{order.billingInfo.country}</p>
                </Col>
                <Col md={4}>
                  <p className="subhead invoice-list">Licensed to</p>
                  <p className="invoice-list">{order.licenseHolder.name}</p>
                  {order.licenseHolder.company && (
                    <p className="invoice-list">
                      {order.licenseHolder.company}
                    </p>
                  )}
                  <p className="invoice-list">
                    {order.licenseHolder.address_one}
                  </p>
                  {order.licenseHolder.address_two && (
                    <p className="invoice-list">
                      {order.licenseHolder.address_two}
                    </p>
                  )}
                  <p className="invoice-list">
                    {order.licenseHolder.city},{" "}
                    {order.licenseHolder.state_province}
                  </p>
                  <p className="invoice-list">
                    {order.licenseHolder.zip_postal_code}
                  </p>
                  <p>{order.licenseHolder.country}</p>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={6}>
                  <p className="subhead invoice-list">Ordered by</p>
                  <p className="invoice-list">{order.user.name}</p>
                  <p>{order.user.email}</p>
                </Col>
                <Col md={6}>
                  <p className="subhead invoice-list">Licensed to</p>
                  <p className="invoice-list">{order.licenseHolder.name}</p>
                  {order.licenseHolder.company && (
                    <p className="invoice-list">
                      {order.licenseHolder.company}
                    </p>
                  )}
                  <p className="invoice-list">
                    {order.licenseHolder.address_one}
                  </p>
                  {order.licenseHolder.address_two && (
                    <p className="invoice-list">
                      {order.licenseHolder.address_two}
                    </p>
                  )}
                  <p className="invoice-list">
                    {order.licenseHolder.city},{" "}
                    {order.licenseHolder.state_province}
                  </p>
                  <p className="invoice-list">
                    {order.licenseHolder.zip_postal_code}
                  </p>
                  <p>{order.licenseHolder.country}</p>
                </Col>
              </Row>
            )}
          </div>
          <Container className="invoice-section">
            <Row>
              <Col md={12}>
                {order.orderItems.map((orderItem, i) => (
                  <Card
                    key={i}
                    className={
                      i === 0
                        ? "invoice-card cart-active"
                        : "invoice-part-card cart-active"
                    }
                  >
                    <Card.Body>
                      <Row>
                        <Col xs={6} className="my-auto">
                          {orderItem.name}
                        </Col>
                        <Col xs={3} className="my-auto">
                          {orderItem.license}
                        </Col>
                        <Col xs={3} className="my-auto text-end">
                          $ {orderItem.price}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
                {order.voucher_code && (
                  <Card className="invoice-voucher-card">
                    <Card.Body>
                      <Row>
                        <Col xs={6} className="my-auto">
                          Voucher{" "}
                          {parseInt(100 * parseFloat(order.voucher_rate))} %
                        </Col>
                        <Col xs={3} className="my-auto cart-fill">
                          Voucher
                        </Col>
                        <Col xs={3} className="my-auto text-end">
                          &minus; $ {order.voucher_amount}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )}
                <Card className="invoice-subtotal-card">
                  <Card.Body>
                    <Row>
                      <Col xs={6} className="my-auto">
                        Subtotal
                      </Col>
                      <Col xs={3} className="my-auto cart-fill">
                        Subtotal
                      </Col>
                      <Col xs={3} className="my-auto text-end">
                        $ {order.subtotal}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="invoice-part-card">
                  <Card.Body>
                    <Row>
                      <Col xs={6} className="my-auto">
                        Tax {parseInt(100 * parseFloat(order.vat_rate))} %
                      </Col>
                      <Col xs={3} className="my-auto cart-fill">
                        Tax
                      </Col>
                      <Col xs={3} className="my-auto text-end">
                        $ {order.tax}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="invoice-total-card">
                  <Card.Body>
                    <Row>
                      <Col xs={6} className="my-auto">
                        Total
                      </Col>
                      <Col xs={3} className="my-auto cart-fill">
                        Total
                      </Col>
                      <Col xs={3} className="my-auto text-end">
                        $ {order.total}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <div className="invoice-footer">
            <Row>
              <Col md={12}>
                <p>Thanks for licensing!</p>
                <p>
                  GarageFonts LLC
                  <br />
                  Pittsburgh, Pennsylvania
                  <br />
                  United States
                  <br />
                  www.garagefonts.com
                  <br />
                  hello@garagefonts.com
                </p>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Container>
  );
}

export default Invoice;
