import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { getUserDetails, updateUserPassword } from "../../actions/userActions";
import { USER_UPDATE_PASSWORD_RESET } from "../../constants/userConstants";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ChangePassword() {
  // Selectors
  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdatePassword = useSelector((state) => state.userUpdatePassword);
  const { success } = userUpdatePassword;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [messageFail, setMessageFail] = useState("");

  // useEffects
  useEffect(() => {
    if (!userInfo) {
      navigate("/login", { replace: true });
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PASSWORD_RESET });
        dispatch(getUserDetails("profile"));
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, navigate, userInfo, user, success]);

  useEffect(() => {
    if (success) {
      setMessageFail("");
      setMessageSuccess("You have successfully changed your password.");
    }
  }, [success]);

  // Submit
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessageFail("Passwords do not match");
    } else {
      dispatch(
        updateUserPassword({
          id: user.id,
          password: password,
        })
      );
    }
  };

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      {loading ? (
        <Loader />
      ) : (
        <>
          {messageFail && (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="danger">{messageFail}</Message>
              </Col>
            </Row>
          )}
          {messageSuccess && (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="success">{messageSuccess}</Message>{" "}
              </Col>
            </Row>
          )}
          {error && (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="danger">{error}</Message>
              </Col>
            </Row>
          )}
          <Form onSubmit={submitHandler}>
            <Row>
              <Col md={12}>
                <h1 className="header">Change password</h1>
              </Col>
              <Col md={12} className="d-none">
                {name}
              </Col>
              <Col md={12} className="d-none">
                {email}
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder=""
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="passwordConfirm">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder=""
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="form-link">
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Container>
  );
}

export default ChangePassword;
