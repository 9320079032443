import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

function AdoreMonoDetail() {
  //Color Scheme
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  return (
    <>
      <Helmet>
        <body className="section-bg" />
      </Helmet>
      <Container fluid className="fonts-contain">
        <>
          <Container>
            <Helmet>
              <title>{`Adore Mono - GarageFonts`}</title>
            </Helmet>
            <FadeIn>
              <Row>
                <Col md={8}>
                  <p className="detail-name">Adore Mono</p>
                  <p className="desc-status">
                    <a
                      href="https://fonts.adobe.com/designers/pieter-van-rosmalen"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pieter van Rosmalen
                    </a>{" "}
                    of Bold Monday and CakeType can be considered one of the
                    kings of monospaced fonts. Way before they became
                    fashionable, Pieter was designing a large array of unique,
                    monospaced designs for GarageFonts in the late 1990s. Adore
                    Mono is the first of these designs that we have optimized.
                    Adore Mono is a monospaced font with a split personality:
                    playful, yet slightly ominous. Its generous forms and grungy
                    texture make it perfect for any gritty, monospaced need, be
                    it a redacted CIA document, a Sleaford Mods concert ad, a
                    sustainable fashion brand, or the text prompts for a
                    slightly menacing AI chatbot.
                  </p>
                  <p className="desc-status">
                    <a href="/font/adore-mono">Type tester and specimens</a>
                  </p>
                  <p className="desc-status">
                    <a href="/details">Back to details</a>
                  </p>
                </Col>
              </Row>
            </FadeIn>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5">
              <Col md={4} className="fonts-image-col">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/adore-mono/adore-story-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/adore-mono/adore-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/adore-mono/adore-story-3.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/adore-mono/adore-story-4.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-co mt-4">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/adore-mono/adore-story-5.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/adore-mono/adore-story-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </>
      </Container>
    </>
  );
}

export default AdoreMonoDetail;
