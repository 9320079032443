import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCountries,
  saveUsaStates,
  saveLicenseHolder,
} from "../../actions/cartActions";
import CheckoutSummary from "./CheckoutSummary";
import CheckoutStepsLic from "./CheckoutStepsLic";

function LicenseHolder() {
  // Selectors
  const cartCountry = useSelector((state) => state.cartCountry);
  const { countries } = cartCountry;

  const cartUsaState = useSelector((state) => state.cartUsaState);
  const { usaStates } = cartUsaState;

  const cartLicenseHolder = useSelector((state) => state.cartLicenseHolder);
  const { licenseHolder } = cartLicenseHolder;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [name, setName] = useState(licenseHolder.name);
  const [company, setCompany] = useState(licenseHolder.company);
  const [addressOne, setAddressOne] = useState(licenseHolder.addressOne);
  const [addressTwo, setAddressTwo] = useState(licenseHolder.addressTwo);
  const [city, setCity] = useState(licenseHolder.city);
  const [stateProvince, setStateProvince] = useState(
    licenseHolder.stateProvince
  );
  const [usaState, setUsaState] = useState(licenseHolder.usaState);
  const [usaStateCode, setUsaStateCode] = useState(licenseHolder.usaStateCode);
  const [zipPostalCode, setZipPostalCode] = useState(
    licenseHolder.zipPostalCode
  );
  const [country, setCountry] = useState(licenseHolder.country);
  const [countryCode, setCountryCode] = useState(licenseHolder.countryCode);
  const [phone, setPhone] = useState(licenseHolder.phone);
  const [licensee, setLicensee] = useState(licenseHolder.licensee);
  const [usaBase, setUsaBase] = useState(false);
  const [countryList, setCountryList] = useState(countries);
  const [usaStateList, setUsaStateList] = useState(usaStates);

  // useEffects
  useEffect(() => {
    if (!countries) {
      dispatch(saveCountries());
      setCountryList([countries]);
    }
    if (!usaStates) {
      dispatch(saveUsaStates());
      setUsaStateList(usaStates);
    }
  }, [dispatch, countries, usaStates]);

  useEffect(() => {
    if (!company) {
      setCompany("");
    }
    if (!addressTwo) {
      setAddressTwo("");
    }
    if (!licensee) {
      setLicensee(true);
    }
  }, [company, addressTwo, licensee]);

  useEffect(() => {
    if (countryCode === "US") {
      setUsaBase(true);
    } else {
      setUsaBase(false);
      setUsaState("");
      setUsaStateCode("");
    }
  }, [countryCode]);

  // On Country
  const onCountry = (e) => {
    var dataset = e.target.options[e.target.selectedIndex].dataset;
    setCountry(e.target.value);
    setCountryCode(dataset.code);
  };

  // On USA State
  const onUsaState = (e) => {
    var dataset = e.target.options[e.target.selectedIndex].dataset;
    setUsaState(e.target.value);
    setUsaStateCode(dataset.code);
    setStateProvince(e.target.value);
  };

  // Submit License Holder
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      saveLicenseHolder({
        name,
        company,
        addressOne,
        addressTwo,
        city,
        stateProvince,
        zipPostalCode,
        country,
        countryCode,
        usaState,
        usaStateCode,
        phone,
        licensee,
      })
    );
    navigate("/checkout/payment-method", { replace: true });
  };

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain form-height">
      <CheckoutStepsLic step1 step2 />
      <Form onSubmit={submitHandler}>
        <Row>
          <Col lg={12}>
            <h1 className="header">License holder information</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="header-text">
            <CheckoutSummary />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder=""
                    value={name ? name : ""}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="company">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={company ? company : ""}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    required
                    onChange={(e) => onCountry(e)}
                    value={country ? country : ""}
                  >
                    <option value="">Select country</option>
                    {countryList.map((country, i) => (
                      <option
                        key={i}
                        value={country.name}
                        data-code={country.code}
                        data-rate={country.vat_rate}
                      >
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6} className={usaBase ? "d-none" : "d-block"}>
                <Form.Group className="mb-4" controlId="stateProvince">
                  <Form.Label>State / province / region</Form.Label>
                  {usaBase && (
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={stateProvince ? stateProvince : ""}
                      onChange={(e) => setStateProvince(e.target.value)}
                    />
                  )}
                  {!usaBase && (
                    <Form.Control
                      required
                      type="text"
                      placeholder=""
                      value={stateProvince ? stateProvince : ""}
                      onChange={(e) => setStateProvince(e.target.value)}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col lg={6} className={usaBase ? "d-block" : "d-none"}>
                <Form.Group className="mb-4" controlId="usaState">
                  <Form.Label>State / province / region</Form.Label>
                  {usaBase && (
                    <Form.Select
                      required
                      onChange={(e) => onUsaState(e)}
                      value={usaState ? usaState : ""}
                    >
                      <option value="">Select state</option>
                      {usaStateList.map((usaState, i) => (
                        <option
                          key={i}
                          value={usaState.name}
                          data-code={usaState.code}
                          data-rate={usaState.tax_rate}
                        >
                          {usaState.name}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                  {!usaBase && (
                    <Form.Select
                      onChange={(e) => onUsaState(e)}
                      value={usaState ? usaState : ""}
                    >
                      <option value="">Select state</option>
                      {usaStateList.map((usaState, i) => (
                        <option
                          key={i}
                          value={usaState.name}
                          data-code={usaState.code}
                          data-rate={usaState.tax_rate}
                        >
                          {usaState.name}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="addressOne">
                  <Form.Label>Address 1</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={addressOne ? addressOne : ""}
                    onChange={(e) => setAddressOne(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="addressTwo">
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={addressTwo ? addressTwo : ""}
                    onChange={(e) => setAddressTwo(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={city ? city : ""}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-4" controlId="zipPostalCode">
                  <Form.Label>Zip / postal code</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={zipPostalCode ? zipPostalCode : ""}
                    onChange={(e) => setZipPostalCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    value={phone ? phone : ""}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="eula-section">
          <Col md={12} className="cart-form-link">
            <Button type="submit" variant="primary">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default LicenseHolder;
