import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in";

function Square({ font, scheme }) {
  return (
    <Container fluid className="fonts-square">
      <FadeIn>
        <Row>
          <Col md={4} className="fonts-image-col mt-4">
            <img
              src={
                "/images/font/" +
                font.slug +
                "/square/" +
                scheme +
                "/" +
                font.slug +
                "-sq-1.svg"
              }
              className="img-fluid spec"
              alt={font.slug + "Use 1"}
            />
          </Col>
          <Col md={4} className="fonts-image-col mt-4">
            <img
              src={
                "/images/font/" +
                font.slug +
                "/square/" +
                scheme +
                "/" +
                font.slug +
                "-sq-2.svg"
              }
              className="img-fluid spec"
              alt={font.slug + "Use 2"}
            />
          </Col>
          <Col md={4} className="fonts-image-col mt-4">
            <img
              src={
                "/images/font/" +
                font.slug +
                "/square/" +
                scheme +
                "/" +
                font.slug +
                "-sq-3.svg"
              }
              className="img-fluid spec"
              alt={font.slug + "Use 3"}
            />
          </Col>
        </Row>
      </FadeIn>
    </Container>
  );
}

export default Square;
