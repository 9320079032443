import {
  GRID_LIST_REQUEST,
  GRID_LIST_SUCCESS,
  GRID_LIST_FAIL,
  SPEC_LIST_REQUEST,
  SPEC_LIST_SUCCESS,
  SPEC_LIST_FAIL,
  CATALOG_LIST_REQUEST,
  CATALOG_LIST_SUCCESS,
  CATALOG_LIST_FAIL,
  SEC_CLIENT_TRIAL_REQUEST,
  SEC_CLIENT_TRIAL_SUCCESS,
  SEC_CLIENT_TRIAL_FAIL,
  CLIENT_DOWNLOAD_REQUEST,
  CLIENT_DOWNLOAD_SUCCESS,
  CLIENT_DOWNLOAD_FAIL,
} from "../constants/sectionConstants";

export const gridListReducer = (state = { grids: [] }, action) => {
  switch (action.type) {
    case GRID_LIST_REQUEST:
      return { loading: true, grids: [] };
    case GRID_LIST_SUCCESS:
      return { loading: false, grids: action.payload };
    case GRID_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const specListReducer = (state = { specs: [] }, action) => {
  switch (action.type) {
    case SPEC_LIST_REQUEST:
      return { loading: true, specs: [] };
    case SPEC_LIST_SUCCESS:
      return { loading: false, specs: action.payload };
    case SPEC_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const catalogListReducer = (state = { catalogs: [] }, action) => {
  switch (action.type) {
    case CATALOG_LIST_REQUEST:
      return { loading: true, catalogs: [] };
    case CATALOG_LIST_SUCCESS:
      return { loading: false, catalogs: action.payload };
    case CATALOG_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const secClientTrialReducer = (state = {}, action) => {
  switch (action.type) {
    case SEC_CLIENT_TRIAL_REQUEST:
      return { loading: true };
    case SEC_CLIENT_TRIAL_SUCCESS:
      return { loading: false, success: true };
    case SEC_CLIENT_TRIAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const clientDownloadReducer = (
  state = { clientTrialRec: {} },
  action
) => {
  switch (action.type) {
    case CLIENT_DOWNLOAD_REQUEST:
      return { ...state, loading: true };
    case CLIENT_DOWNLOAD_SUCCESS:
      return { loading: false, clientTrialRec: action.payload };
    case CLIENT_DOWNLOAD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
