import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Info({ font }) {
  // State Etc
  const [designers, setDesigners] = useState([]);
  const [variations, setVariations] = useState([]);
  const [relations, setRelations] = useState([]);

  // useEffects
  useEffect(() => {
    setDesigners(
      font.designers.map((item) => ({
        ...item,
      }))
    );
  }, [font.designers]);

  useEffect(() => {
    setVariations(
      font.variations.map((item) => ({
        ...item,
      }))
    );
  }, [font.variations]);

  useEffect(() => {
    setRelations(
      font.relations.map((item) => ({
        ...item,
      }))
    );
  }, [font.relations]);

  return (
    <Container id="info" className="fonts-info">
      <Row>
        <Col md={12}>
          <p className="font-section">{font.name} Info</p>
        </Col>
        {font.desc && (
          <Col md={8}>
            <p className="desc-status">{font.desc}</p>
          </Col>
        )}
        {designers.length === 1 ? (
          <Col md={12}>
            {designers
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((designer, i) => (
                <p key={i} className="desc-status">
                  Designer:&nbsp;
                  <a href={designer.link} target="_blank" rel="noreferrer">
                    {designer.name}
                  </a>{" "}
                  ({designer.location})
                </p>
              ))}
          </Col>
        ) : (
          <Col md={12}>
            {designers
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((designer, i) => (
                <p key={i} className="desc-status">
                  Designers:&nbsp;
                  <a href={designer.link} target="_blank" rel="noreferrer">
                    {designer.name}
                  </a>{" "}
                  ({designer.location})
                </p>
              ))}
          </Col>
        )}
        <Col md={12}>
          <p className="desc-status">Version: {font.version}</p>
        </Col>
        <Col md={12}>
          <p className="desc-status">
            Tier: <Link to="/tiers">{font.tier}</Link>
          </p>
        </Col>
        {font.inclusion && (
          <Col md={12}>
            <p className="desc-status">{font.inclusion}</p>
          </Col>
        )}
        {variations.length > 0 && (
          <Col md={12}>
            <p className="desc-status">
              Related fonts:{" "}
              {variations
                .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                .map((variation, i) => (
                  <>
                    <a href={"/font/" + font.slug + "-variable/"}>
                      {variation.name}
                    </a>{" "}
                  </>
                ))}
            </p>
          </Col>
        )}
        {relations.length > 0 && (
          <Col md={12}>
            <p className="desc-status">
              Related fonts:{" "}
              {relations
                .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                .map((relation, i) => (
                  <>
                    <a href={"/font/" + relation.slug}>{relation.name}</a>{" "}
                  </>
                ))}
            </p>
          </Col>
        )}
        {font.pdf && (
          <Col md={12}>
            <p className="desc-status">
              <i className="bi bi-cloud-arrow-down-fill"></i>{" "}
              <a
                href={"/pdfs/spec/" + font.slug + "/" + font.pdf}
                target="_blank"
                rel="noreferrer"
              >
                Specimen
              </a>
            </p>
          </Col>
        )}
        {font.glyph_set && (
          <Col md={12}>
            <p className="desc-status">
              <i className="bi bi-cloud-arrow-down-fill"></i>{" "}
              <a
                href={"/pdfs/glyphs/" + font.slug + "/" + font.glyph_set}
                target="_blank"
                rel="noreferrer"
              >
                Glyph Set
              </a>
            </p>
          </Col>
        )}
        {font.adobe_sync && (
          <Col md={12}>
            <p className="desc-status">
              <i class="bi bi-link"></i>{" "}
              <a href={font.adobe_sync} target="_blank" rel="noreferrer">
                Sync on Adobe Fonts
              </a>
            </p>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default Info;
