import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listCollectionDetail } from "../../actions/collectionActions";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../main/Loader";
import Message from "../main/Message";

function Collection() {
  // Selectors
  const collectionDetail = useSelector((state) => state.collectionDetail);
  const { error, loading, collection } = collectionDetail;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  const [fonts, setFonts] = useState([]);
  const { slug } = useParams();

  // useEffects
  useEffect(() => {
    dispatch(listCollectionDetail(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    setFonts(
      collection.fonts.map((item) => ({
        ...item,
      }))
    );
  }, [collection.fonts]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top home-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top home-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain home-bg";
  }, []);

  return (
    <Container className="collect-contain">
      <Helmet>
        <body className="home-bg" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : error ? (
        <Row className="message-contain">
          <Col md={12}>
            <Message variant="danger">{error}</Message>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col md={12}>
              <div>
                <p>{collection.name}</p>
              </div>
            </Col>
            {fonts
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((font, i) => (
                <Col key={i} md={12} className="library-item">
                  <div className="vw-spec">
                    <Link to={"/font/" + font.slug} className={font.slug}>
                      {font.name}
                    </Link>
                  </div>
                  <div>
                    <p>
                      <Link to={"/font/" + font.slug}>{font.desc_short}</Link>
                    </p>
                  </div>
                </Col>
              ))}
          </Row>
        </>
      )}
    </Container>
  );
}

export default Collection;
