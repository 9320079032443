import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Discounts() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>Discounts - GarageFonts</title>
          <meta property="og:title" content="Discounts - GarageFonts" />
        </Helmet>
        <Row>
          <Col md={12}>
            <h1 className="header">Support</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/licenses" className="nav-link">
                  Licenses
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/eula" className="nav-link">
                  EULA
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/tiers" className="nav-link">
                  Tiers
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/discounts" className="nav-link active">
                  Discounts
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/policies" className="nav-link">
                  Policies
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="mt-4">
            <p>STUDENT DISCOUNT</p>
            <p>
              We offer a student discount on all our fonts. E-mail us a copy of
              your valid student ID and we will send you a voucher code for a
              50% discount in return. Student licenses are perpetual, this means
              that you can still use the licensed fonts after you've left your
              educational institution.
            </p>
            <p>-------------</p>
            <p>UNIVERSITY DISCOUNT</p>
            <p>
              We offer universities, schools, and other educational institutions
              a 50% discount on our entire collection. Please get in touch for
              an estimate.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Discounts;
