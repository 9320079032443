import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function NotFound() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      <Row>
        <Col md={12}>
          <h1 className="header">Page not found</h1>
          <p>Aw man! The page you're looking for is lost in the garage.</p>
          <p>
            Check out the <Link to="/">homepage</Link> or{" "}
            <Link to="/contact">drop us a line</Link>.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
