export const GRID_LIST_REQUEST = "GRID_LIST_REQUEST";
export const GRID_LIST_SUCCESS = "GRID_LIST_SUCCESS";
export const GRID_LIST_FAIL = "GRID_LIST_FAIL";
export const SPEC_LIST_REQUEST = "SPEC_LIST_REQUEST";
export const SPEC_LIST_SUCCESS = "SPEC_LIST_SUCCESS";
export const SPEC_LIST_FAIL = "SPEC_LIST_FAIL";
export const CATALOG_LIST_REQUEST = "CATALOG_LIST_REQUEST";
export const CATALOG_LIST_SUCCESS = "CATALOG_LIST_SUCCESS";
export const CATALOG_LIST_FAIL = "CATALOG_LIST_FAIL";
export const SEC_CLIENT_TRIAL_REQUEST = "SEC_CLIENT_TRIAL_REQUEST";
export const SEC_CLIENT_TRIAL_SUCCESS = "SEC_CLIENT_TRIAL_SUCCESS";
export const SEC_CLIENT_TRIAL_FAIL = "SEC_CLIENT_TRIAL_FAIL";
export const CLIENT_DOWNLOAD_REQUEST = "CLIENT_DOWNLOAD_REQUEST";
export const CLIENT_DOWNLOAD_SUCCESS = "CLIENT_DOWNLOAD_SUCCESS";
export const CLIENT_DOWNLOAD_FAIL = "CLIENT_DOWNLOAD_FAIL";
