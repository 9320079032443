import { Row, Col, Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <Row className="loader-contain">
      <Col className="text-center">
        <Spinner animation="grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    </Row>
  );
};

export default Loader;
