import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

function FuquaVarDetail() {
  //Color Scheme
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  return (
    <>
      <Helmet>
        <body className="section-bg" />
      </Helmet>
      <Container fluid className="fonts-contain">
        <>
          <Container>
            <Helmet>
              <title>{`Fuqua - GarageFonts`}</title>
            </Helmet>
            <FadeIn>
              <Row>
                <Col md={8}>
                  <p className="detail-name">Fuqua Variable</p>
                  <p className="desc-status">
                    Fuqua is a slab serif family that is solid and sturdy, yet
                    warm and friendly. A contemporary slab with wide, full forms
                    and weighty foundations. It is a highly versatile typeface
                    family. The weights towards the thin and ultra extremes have
                    a striking character and contain unique detailing that makes
                    them very useful in display and headline settings, while the
                    middle weights are remarkably readable at small sizes and
                    create a firm impression within text. Comfortable in both
                    serious and playful environments, Fuqua can be a valuable
                    tool for both discerning typographers and those simply
                    seeking to create a dependable identity for their company or
                    event.
                  </p>
                  <p className="desc-status">
                    Released in 2024, Fuqua is available in 20 styles and 2
                    variable fonts. The typeface was initiated at TypeParis by
                    Jon Young (
                    <a
                      href="https://tiotype.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      @tiotype
                    </a>
                    ) in 2017. The 7-year project included consistent rounds of
                    modification and refinement and was enhanced by invaluable
                    advice from renowned type designers such as Jean François
                    Porchez (Typofonderie), Mário Feliciano (Feliciano Type),
                    Veronika Burian (TypeTogether), Stephen Nixon (Arrow Type),
                    and Pieter van Rosmalen (Bold Monday).
                  </p>
                  <p className="desc-status">
                    <Link to="/font/fuqua-variable" className="info-nav">
                      Type tester and specimens
                    </Link>
                  </p>
                  <p className="desc-status">
                    <Link to="/details" className="info-nav">
                      Back to details
                    </Link>
                  </p>
                </Col>
              </Row>
            </FadeIn>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5">
              <Col md={4} className="fonts-image-col">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-1b.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ig-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ig-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ig-3.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-4.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-5.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/fuqua/fuqua-rec-anim-1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/fuqua/fuqua-rec-anim-2.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/fuqua/fuqua-rec-anim-3.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={3} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ag-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={3} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ag-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={3} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ag-3.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={3} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ag-4.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </>
      </Container>
    </>
  );
}

export default FuquaVarDetail;
