import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  ORDER_TRIAL_INTENT_REQUEST,
  ORDER_TRIAL_INTENT_SUCCESS,
  ORDER_TRIAL_INTENT_FAIL,
  ORDER_INTENT_REQUEST,
  ORDER_INTENT_SUCCESS,
  ORDER_INTENT_FAIL,
  ORDER_TRIAL_REQUEST,
  ORDER_TRIAL_SUCCESS,
  ORDER_TRIAL_FAIL,
  ORDER_PAYPAL_REQUEST,
  ORDER_PAYPAL_SUCCESS,
  ORDER_PAYPAL_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PROFILE_ORDERS_REQUEST,
  ORDER_PROFILE_ORDERS_SUCCESS,
  ORDER_PROFILE_ORDERS_FAIL,
  ORDER_PROFILE_PURS_REQUEST,
  ORDER_PROFILE_PURS_SUCCESS,
  ORDER_PROFILE_PURS_FAIL,
  ORDER_PROFILE_TRIALS_REQUEST,
  ORDER_PROFILE_TRIALS_SUCCESS,
  ORDER_PROFILE_TRIALS_FAIL,
  ORDER_PROFILE_ADDS_REQUEST,
  ORDER_PROFILE_ADDS_SUCCESS,
  ORDER_PROFILE_ADDS_FAIL,
} from "../constants/orderConstants";

import {
  CART_CLEAR_ITEMS,
  CART_VOUCHER_VALID_RESET,
} from "../constants/cartConstants";

export const clearOrder = () => (dispatch) => {
  localStorage.removeItem("cartItems");
  localStorage.removeItem("voucherValid");
  dispatch({ type: CART_CLEAR_ITEMS });
  dispatch({ type: CART_VOUCHER_VALID_RESET });
};

export const createTrialIntentOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_TRIAL_INTENT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const createTrialIntentUrl = `${endpoint}/orders/add-trial-intent-order/`;
    const { data } = await axios.post(createTrialIntentUrl, order, config);

    dispatch({
      type: ORDER_TRIAL_INTENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_TRIAL_INTENT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createIntentOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_INTENT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const createIntentUrl = `${endpoint}/orders/add-intent-order/`;
    const { data } = await axios.post(createIntentUrl, order, config);

    dispatch({
      type: ORDER_INTENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_INTENT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createTrialOrder = (ref) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_TRIAL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const createTrialUrl = `${endpoint}/orders/add-trial-order/${ref}/`;
    const { data } = await axios.post(createTrialUrl, config);

    dispatch({
      type: ORDER_TRIAL_SUCCESS,
      payload: data,
    });

    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_TRIAL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createPayPalOrder =
  (ref, orderId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAYPAL_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const endpoint = `${HOST}${API}`;
      const createPayPalUrl = `${endpoint}/orders/add-paypal-order/${ref}/${orderId}/`;
      const { data } = await axios.post(createPayPalUrl, config);

      dispatch({
        type: ORDER_PAYPAL_SUCCESS,
        payload: data,
      });

      dispatch({
        type: CART_CLEAR_ITEMS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_PAYPAL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const getOrderUrl = `${endpoint}/orders/${id}/`;
    const { data } = await axios.get(getOrderUrl, config);

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getProfileOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_PROFILE_ORDERS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const getProfileOrdersUrl = `${endpoint}/orders/profile-orders/`;
    const { data } = await axios.get(getProfileOrdersUrl, config);

    dispatch({
      type: ORDER_PROFILE_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_PROFILE_ORDERS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getProfilePurchases = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_PROFILE_PURS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const getProfilePursUrl = `${endpoint}/orders/profile-purchases/`;
    const { data } = await axios.get(getProfilePursUrl, config);

    dispatch({
      type: ORDER_PROFILE_PURS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_PROFILE_PURS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getProfileTrials = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_PROFILE_TRIALS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const getProfileTrialsUrl = `${endpoint}/orders/profile-trials/`;
    const { data } = await axios.get(getProfileTrialsUrl, config);

    dispatch({
      type: ORDER_PROFILE_TRIALS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_PROFILE_TRIALS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getProfileAdditions = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_PROFILE_ADDS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const endpoint = `${HOST}${API}`;
    const getProfileAddsUrl = `${endpoint}/orders/profile-additions/`;
    const { data } = await axios.get(getProfileAddsUrl, config);

    dispatch({
      type: ORDER_PROFILE_ADDS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_PROFILE_ADDS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
