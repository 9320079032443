import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Collapse,
} from "react-bootstrap";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

function AllDetail() {
  const [open, setOpen] = useState(false);

  //Color Scheme
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  return (
    <>
      <Helmet>
        <body className="section-bg" />
      </Helmet>
      <Container fluid className="fonts-contain">
        <>
          <Container>
            <Helmet>
              <title>{`Adore Mono - GarageFonts`}</title>
            </Helmet>
            <FadeIn>
              <Row id="top">
                <Col md={10}>
                  <p className="detail-name">GarageFonts in use</p>
                  <a href="#fuqua-variable">Fuqua Variable</a>,{" "}
                  <a href="#arbuckle">Arbuckle</a>,{" "}
                  <a href="#homage-condensed">Homage Condensed</a>,{" "}
                  <a href="#homage-script">Homage Script</a>,{" "}
                  <a href="#adore-mono">Adore Mono</a>,{" "}
                  <a href="#pipeline">Archive</a>,{" "}
                  <a href="#pipeline">Weekend</a>,{" "}
                  <a href="#pipeline">Clarice</a>, <a href="#pipeline">Fono</a>,{" "}
                  <a href="#pipeline">Kienan</a>, <a href="#pipeline">Kamaro</a>
                  , <a href="#pipeline">Karazan</a>,{" "}
                  <a href="#pipeline">Klif</a>
                </Col>
              </Row>
            </FadeIn>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5" id="fuqua-variable">
              <Col md={12}>
                <hr />
                <p>
                  <Button variant="link" href="/font/fuqua-variable">
                    Fuqua Variable
                  </Button>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="light" href="/font/fuqua-variable">
                      Test
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Info
                    </Button>
                    <Button variant="light" href="#top">
                      Back to top
                    </Button>
                  </ButtonGroup>
                </p>
                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <p className="desc-status">
                      Fuqua is a slab serif family that is solid and sturdy, yet
                      warm and friendly. A contemporary slab with wide, full
                      forms and weighty foundations. It is a highly versatile
                      typeface family. The weights towards the thin and ultra
                      extremes have a striking character and contain unique
                      detailing that makes them very useful in display and
                      headline settings, while the middle weights are remarkably
                      readable at small sizes and create a firm impression
                      within text. Comfortable in both serious and playful
                      environments, Fuqua can be a valuable tool for both
                      discerning typographers and those simply seeking to create
                      a dependable identity for their company or event.
                    </p>
                    <hr />
                  </div>
                </Collapse>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-1b.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ig-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ig-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ig-3.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-4.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-5.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-detail-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/fuqua/fuqua-rec-anim-1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/fuqua/fuqua-rec-anim-2.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/fuqua/fuqua-rec-anim-3.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={3} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ag-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={3} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ag-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={3} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ag-3.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={3} className="fonts-image-col mt-4">
                <Link to="/font/fuqua-variable" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/fuqua/fuqua-ag-4.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5" id="arbuckle">
              <Col md={12}>
                <hr />
                <p>
                  <Button variant="link" href="/font/arbuckle">
                    Arbuckle
                  </Button>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="light" href="/font/arbuckle">
                      Test
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Info
                    </Button>
                    <Button variant="light" href="#top">
                      Back to top
                    </Button>
                  </ButtonGroup>
                </p>
                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <p className="desc-status">
                      Arbuckle is certainly one of the earlier "bubble font"
                      designs, having been originally released in 2001. It was
                      designed by{" "}
                      <a
                        href="https://www.woodardworks.com/56.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Robby Woodard
                      </a>{" "}
                      and was inspired by the lettering in light-hearted comic
                      books such as Garfield. Bubbly fonts are popular at the
                      moment, considering recent releases such as Ohno Type's
                      Softie and Tipastype's Sandhouse, to name two buoyant
                      designs. Arbuckle fits great in the genre, and has the
                      additional bonus of being a layer font with a robust
                      character set. Arbuckle's layering capabilities give the
                      user the ability to control the color of the contours and
                      highlights of the letterforms. Be it packaging for bubble
                      gum, kids books about pets or clouds, ice cream
                      advertisements, you name it, Arbuckle blows a big bubble!
                    </p>
                    <hr />
                  </div>
                </Collapse>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/arbuckle/arbuckle-story-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/arbuckle/arbuckle-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/arbuckle/arbuckle-story-3.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/arbuckle/arbuckle-story-4.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/arbuckle/arbuckle-story-5.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/arbuckle/arbuckle-story-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5" id="homage-condensed">
              <Col md={12}>
                <hr />
                <p>
                  <Button variant="link" href="/font/homage-condensed">
                    Homage Condensed
                  </Button>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="light" href="/font/homage-condensed">
                      Test
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Info
                    </Button>
                    <Button variant="light" href="#top">
                      Back to top
                    </Button>
                  </ButtonGroup>
                </p>
                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <p className="desc-status">
                      Paying homage to the 1960s and 1970s designs of Tom
                      Carnase and Herb Lubalin, Homage Condensed is a digital
                      revival and recutting of LSC Condensed in two weights
                      along with italics and numerous stylistic alternate
                      characters. It was designed by International Typefounders
                      and Phil's Fonts and originally released in 2016. Great
                      for large, condensed headlines, it is elegant but also
                      adds a retro flavor due to its 1970s roots and classic ad
                      agency stylings. Homage Condensed's character set features
                      a large array of alternates and OT features that make it a
                      blast to use when executing bold and dynamic headlines and
                      social media stories.
                    </p>
                    <hr />
                  </div>
                </Collapse>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-condensed/homage-c-story-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/homage-condensed/homage-c-story-3.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-condensed/homage-c-story-7.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-condensed/homage-c-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/homage-condensed/homage-c-story-5.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-condensed" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-condensed/homage-c-story-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5" id="homage-script">
              <Col md={12}>
                <hr />
                <p>
                  <Button variant="link" href="/font/homage-script">
                    Homage Script
                  </Button>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="light" href="/font/homage-script">
                      Test
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Info
                    </Button>
                    <Button variant="light" href="#top">
                      Back to top
                    </Button>
                  </ButtonGroup>
                </p>
                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <p className="desc-status">
                      Graceful, elegant, and at times eccentric, Homage Script
                      was inspired by James Hellmuth's hand-lettered design for
                      the cover of “Homage to the Alphabet” — a gigantic tome
                      produced in 1980 to provide full-showings of
                      photolettering fonts available for traditional typesetting
                      at Phil's Photo. It was designed by International
                      Typefounders and Phil's Fonts and originally released in
                      2016. Great for large headlines and wedding invitations,
                      Homage Script is elegant but adds zest and vitality due to
                      its unconventional flourishes and sumptuous contours. It
                      has a fun character set chock full of alternates and OT
                      features. The features enable typographers and casual
                      designers alike to find just the right connection or
                      ornamental flow they might need to achieve a tasteful
                      typographic statement.
                    </p>
                    <hr />
                  </div>
                </Collapse>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-script/homage-s-story-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/homage-script/homage-s-story-2.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-script/homage-s-story-3.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-script/homage-s-story-4.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/homage-script/homage-s-story-5.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-script/homage-s-story-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5" id="adore-mono">
              <Col md={12}>
                <hr />
                <p>
                  <Button variant="link" href="/font/adore-mono">
                    Adore Mono
                  </Button>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="light" href="/font/adore-mono">
                      Test
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Info
                    </Button>
                    <Button variant="light" href="#top">
                      Back to top
                    </Button>
                  </ButtonGroup>
                </p>
                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <p className="desc-status">
                      <a
                        href="https://fonts.adobe.com/designers/pieter-van-rosmalen"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pieter van Rosmalen
                      </a>{" "}
                      of Bold Monday and CakeType can be considered one of the
                      kings of monospaced fonts. Way before they became
                      fashionable, Pieter was designing a large array of unique,
                      monospaced designs for GarageFonts in the late 1990s.
                      Adore Mono is the first of these designs that we have
                      optimized. Adore Mono is a monospaced font with a split
                      personality: playful, yet slightly ominous. Its generous
                      forms and grungy texture make it perfect for any gritty,
                      monospaced need, be it a redacted CIA document, a Sleaford
                      Mods concert ad, a sustainable fashion brand, or the text
                      prompts for a slightly menacing AI chatbot.
                    </p>
                    <hr />
                  </div>
                </Collapse>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/adore-mono/adore-story-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/adore-mono/adore-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/adore-mono/adore-story-3.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/adore-mono/adore-story-4.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-co mt-4">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/adore-mono/adore-story-5.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/adore-mono" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/adore-mono/adore-story-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5" id="pipeline">
              <Col md={12}>
                <hr />
                <p>
                  <Button variant="link" href="/detail/pipeline">
                    Pipeline
                  </Button>
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="light"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                    >
                      Info
                    </Button>
                    <Button variant="light" href="#top">
                      Back to top
                    </Button>
                  </ButtonGroup>
                </p>
                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <p className="desc-status">
                      We currently have a long list of fonts in the pipeline. As
                      one of the oldest operating digital type foundries in the
                      world, GarageFonts has had a long history of releasing
                      unique display typeface designs, most of which were
                      designed sometime in the 1990s. The original GarageFonts
                      are not only historic and groundbreaking but also highly
                      expressive, experimental, and fun. Fonts in the pipeline
                      include designs from this previous generation of
                      GarageFonts being prepared for re-release and brand new
                      designs as well.
                    </p>
                    <p className="desc-status">
                      Fonts we are currently optimizing include:{" "}
                      <Link to="/font/archive" className="info-nav">
                        Archive
                      </Link>{" "}
                      and the{" "}
                      <Link to="/font/nice-weekend" className="info-nav">
                        Weekend Suite
                      </Link>{" "}
                      by Pieter van Rosmalen,{" "}
                      <Link to="/font/clarice" className="info-nav">
                        Clarice
                      </Link>{" "}
                      by Robby Woodard,{" "}
                      <Link to="/font/fono" className="info-nav">
                        Fono
                      </Link>{" "}
                      by Thomas Mettendorf,{" "}
                      <Link to="/font/kienan" className="info-nav">
                        Kienan
                      </Link>{" "}
                      by Phil's Fonts, and{" "}
                      <Link to="/font/karazan" className="info-nav">
                        Karazan
                      </Link>{" "}
                      and{" "}
                      <Link to="/font/klif" className="info-nav">
                        Klif
                      </Link>{" "}
                      by Lionel Barat.
                    </p>
                    <hr />
                  </div>
                </Collapse>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/archive" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/pipeline/archive-story-2.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/clarice" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/clarice-story-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/dotted-weekend" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/dotted-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/kamaro" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/kamaro-story-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fono" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/pipeline/fono-story-1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/karazan" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/karazan-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/klif" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/klif-story-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/kienan" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/kienan-story-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/nice-weekend" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/pipeline/weekend-story-1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </>
      </Container>
    </>
  );
}

export default AllDetail;
