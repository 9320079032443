import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listHomes } from "../../actions/homeActions";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../main/Loader";
import Message from "../main/Message";
import FadeIn from "react-fade-in";

function Home() {
  // Selectors
  const homeList = useSelector((state) => state.homeList);
  const { error, loading, homes } = homeList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);
  const randomHomes = shuffle(homes);

  // useEffects
  useEffect(() => {
    dispatch(listHomes());
  }, [dispatch]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top home-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top home-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain home-bg";
  }, []);

  return (
    <>
      <Container fluid className="square-contain">
        <Helmet>
          <body className="home-bg" />
        </Helmet>
        {loading ? (
          <Loader />
        ) : error ? (
          <Row className="message-contain">
            <Col md={12}>
              <Message variant="danger">{error}</Message>
            </Col>
          </Row>
        ) : (
          <>
            <FadeIn>
              <Row>
                <Col md={12}>
                  <p>GarageFonts</p>
                  <p>30+ years of eclectic type</p>
                </Col>
              </Row>
              <Row>
                {randomHomes
                  .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                  .map((home, i) => (
                    <Col key={i} md={4}>
                      <div className="image-square">
                        <a href={"/font/" + home.slug}>
                          <img
                            src={
                              "/images/home/" + home.slug + "/" + home.source
                            }
                            className="img-fluid"
                            alt={home.slug}
                          />
                        </a>
                      </div>
                      {home.status ? (
                        <>
                          <p className="name">
                            <a href={"/font/" + home.slug}>{home.name}</a>{" "}
                            <span class="badge bg-light text-dark">
                              {home.status}
                            </span>
                          </p>
                        </>
                      ) : (
                        <p className="name">
                          <a href={"/font/" + home.slug}>{home.name}</a>
                        </p>
                      )}
                    </Col>
                  ))}
              </Row>
            </FadeIn>
          </>
        )}
      </Container>
    </>
  );
}

export default Home;
