import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listCatalogs } from "../../actions/sectionActions";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../main/Loader";
import Message from "../main/Message";
import { createGlobalStyle } from "styled-components";

// Global Styles
const WoffCatalog = createGlobalStyle`.${(props) =>
  props.catalog.font_class}{font-family: "${(props) =>
  props.catalog.woff_name}";}`;

function Catalog() {
  // Selectors
  const catalogList = useSelector((state) => state.catalogList);
  const { error, loading, catalogs } = catalogList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);
  const randomCatalogs = shuffle(catalogs);

  // useEffects
  useEffect(() => {
    dispatch(listCatalogs());
  }, [dispatch]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top catalog-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top catalog-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain catalog-bg";
  }, []);

  return (
    <Container fluid className="catalog-contain">
      <Helmet>
        <body className="catalog-bg" />
        <title>Catalog - GarageFonts</title>
        <meta property="og:title" content="Catalog - GarageFonts" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : error ? (
        <Row className="message-contain">
          <Col md={12}>
            <Message variant="danger">{error}</Message>
          </Col>
        </Row>
      ) : (
        <>
          <Container className="catalog-title">
            <Row>
              <Col md={12}>
                <p>GarageFonts</p>
                <p>30+ years of eclectic type</p>
              </Col>
            </Row>
          </Container>
          {randomCatalogs
            .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
            .map((catalog, i) => (
              <>
                <Row key={i} className="catalog-font">
                  <WoffCatalog catalog={catalog} />
                  <Col md={12} className="fonts-image-col">
                    <p
                      className={catalog.font_class + " catalog-text"}
                      style={{
                        fontSize: catalog.size + "vw",
                        lineHeight: catalog.lh + "em",
                        fontVariantLigatures: "discretionary-ligatures",
                      }}
                    >
                      <a href={"/font/" + catalog.slug}>{catalog.text}</a>
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <a href={"/font/" + catalog.slug}>{catalog.name}</a>
                    </p>
                  </Col>
                </Row>
              </>
            ))}
        </>
      )}
    </Container>
  );
}

export default Catalog;
