import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

function HomageScriptDetail() {
  //Color Scheme
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  return (
    <>
      <Helmet>
        <body className="section-bg" />
      </Helmet>
      <Container fluid className="fonts-contain">
        <>
          <Container>
            <Helmet>
              <title>{`Arbuckle - GarageFonts`}</title>
            </Helmet>
            <FadeIn>
              <Row>
                <Col md={8}>
                  <p className="detail-name">Homage Script</p>
                  <p className="desc-status">
                    Graceful, elegant, and at times eccentric, Homage Script was
                    inspired by James Hellmuth's hand-lettered design for the
                    cover of “Homage to the Alphabet” — a gigantic tome produced
                    in 1980 to provide full-showings of photolettering fonts
                    available for traditional typesetting at Phil's Photo. It
                    was designed by International Typefounders and Phil's Fonts
                    and originally released in 2016. Great for large headlines
                    and wedding invitations, Homage Script is elegant but adds
                    zest and vitality due to its unconventional flourishes and
                    sumptuous contours. It has a fun character set chock full of
                    alternates and OT features. The features enable typographers
                    and casual designers alike to find just the right connection
                    or ornamental flow they might need to achieve a tasteful
                    typographic statement.
                  </p>
                  <p className="desc-status">
                    <a href="/font/homage-script">Type tester and specimens</a>
                  </p>
                  <p className="desc-status">
                    <a href="/details">Back to details</a>
                  </p>
                </Col>
              </Row>
            </FadeIn>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5">
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-script/homage-s-story-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/homage-script/homage-s-story-2.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-script/homage-s-story-3.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-script/homage-s-story-4.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/homage-script/homage-s-story-5.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/homage-script" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/homage-script/homage-s-story-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </>
      </Container>
    </>
  );
}

export default HomageScriptDetail;
