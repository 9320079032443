import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  GRID_LIST_REQUEST,
  GRID_LIST_SUCCESS,
  GRID_LIST_FAIL,
  SPEC_LIST_REQUEST,
  SPEC_LIST_SUCCESS,
  SPEC_LIST_FAIL,
  CATALOG_LIST_REQUEST,
  CATALOG_LIST_SUCCESS,
  CATALOG_LIST_FAIL,
  SEC_CLIENT_TRIAL_REQUEST,
  SEC_CLIENT_TRIAL_SUCCESS,
  SEC_CLIENT_TRIAL_FAIL,
  CLIENT_DOWNLOAD_REQUEST,
  CLIENT_DOWNLOAD_SUCCESS,
  CLIENT_DOWNLOAD_FAIL,
} from "../constants/sectionConstants";

export const listGrids = () => async (dispatch) => {
  try {
    dispatch({ type: GRID_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const gridListUrl = `${endpoint}/grids/`;
    const { data } = await axios.get(gridListUrl);

    dispatch({ type: GRID_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GRID_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSpecs = () => async (dispatch) => {
  try {
    dispatch({ type: SPEC_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const specListUrl = `${endpoint}/specs/`;
    const { data } = await axios.get(specListUrl);

    dispatch({ type: SPEC_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SPEC_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listCatalogs = () => async (dispatch) => {
  try {
    dispatch({ type: CATALOG_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const catalogListUrl = `${endpoint}/catalogs/`;
    const { data } = await axios.get(catalogListUrl);

    dispatch({ type: CATALOG_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATALOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const clientTrial =
  (name, email, company, newsletter) => async (dispatch) => {
    try {
      dispatch({
        type: SEC_CLIENT_TRIAL_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const endpoint = `${HOST}${API}`;
      const secClientTrialrUrl = `${endpoint}/section/add-client-trial/`;
      const { data } = await axios.post(
        secClientTrialrUrl,
        {
          name: name,
          email: email,
          company: company,
          newsletter: newsletter,
        },
        config
      );

      dispatch({
        type: SEC_CLIENT_TRIAL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SEC_CLIENT_TRIAL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listClientDownload = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_DOWNLOAD_REQUEST });
    const endpoint = `${HOST}${API}`;
    const clientDownloadUrl = `${endpoint}/section/get-client-download/`;
    const { data } = await axios.get(clientDownloadUrl);

    dispatch({ type: CLIENT_DOWNLOAD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CLIENT_DOWNLOAD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
