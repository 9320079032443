import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listGrids } from "../../actions/sectionActions";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../main/Loader";
import Message from "../main/Message";

function Grid() {
  // Selectors
  const gridList = useSelector((state) => state.gridList);
  const { error, loading, grids } = gridList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);
  const randomGrids = shuffle(grids);

  const cloudinaryLoc =
    "https://res.cloudinary.com/daxjsdnlo/image/upload/v1681479437/grid/";

  // useEffects
  useEffect(() => {
    dispatch(listGrids());
  }, [dispatch]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top home-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top home-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain home-bg";
  }, []);

  return (
    <Container fluid className="square-contain">
      <Helmet>
        <body className="home-bg" />
        <title>Gallery - GarageFonts</title>
        <meta property="og:title" content="Gallery - GarageFonts" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : error ? (
        <Row className="message-contain">
          <Col md={12}>
            <Message variant="danger">{error}</Message>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            {randomGrids
              // .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((grid, i) => (
                <Col key={i} md={4}>
                  <div className="image-square">
                    {grid.font ? (
                      <a href={"/font/" + grid.link}>
                        <img
                          src={cloudinaryLoc + grid.source}
                          className="img-fluid grid"
                          alt={grid.name}
                        />
                      </a>
                    ) : (
                      <a href={grid.link} target="_blank" rel="noreferrer">
                        <img
                          src={cloudinaryLoc + grid.source}
                          className="img-fluid grid"
                          alt={grid.name}
                        />
                      </a>
                    )}
                  </div>
                </Col>
              ))}
          </Row>
        </>
      )}
    </Container>
  );
}

export default Grid;
