import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";

function Blog0003() {
  // Footer
  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain";
  }, []);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Row>
          <Col md={12}>
            <h1 className="header">Fuqua Release</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <p>11/13/2023</p>
            <p>
              GarageFonts is excited to release Fuqua! This is a typeface I
              began work on at TypeParis in 2017.
            </p>
            <p>---------</p>
            <p>Initial Drawings:</p>
            <p>---------</p>
            <p>—Jon</p>
            <p>---------</p>
            <p>
              <Link to="/blog">Back to GF Blog</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Blog0003;
