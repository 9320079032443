import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function TrialSteps({ step1, step2, step3 }) {
  return (
    <>
      <Breadcrumb>
        Trial Checkout&nbsp;|&nbsp;
        {step1 ? (
          <LinkContainer to="/checkout/trial/license-holder">
            <Breadcrumb.Item>License Holder</Breadcrumb.Item>
          </LinkContainer>
        ) : (
          <LinkContainer to="/checkout/trial/license-holder">
            <Breadcrumb.Item className="breadcrumb-disabled">
              License Holder
            </Breadcrumb.Item>
          </LinkContainer>
        )}
        {step2 ? (
          <Breadcrumb.Item>Submit Order</Breadcrumb.Item>
        ) : (
          <LinkContainer to="/checkout/trial/submit-order">
            <Breadcrumb.Item className="breadcrumb-disabled">
              Submit Order
            </Breadcrumb.Item>
          </LinkContainer>
        )}
        {step3 ? (
          <Breadcrumb.Item>Complete</Breadcrumb.Item>
        ) : (
          <LinkContainer to="/checkout/trial/complete">
            <Breadcrumb.Item className="breadcrumb-disabled">
              Complete
            </Breadcrumb.Item>
          </LinkContainer>
        )}
      </Breadcrumb>
    </>
  );
}

export default TrialSteps;
