import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { makeDownload } from "../../actions/userActions";
import { useParams } from "react-router-dom";
import Loader from "../main/Loader";
import Message from "../main/Message";

function Download() {
  // Selectors
  const userDownload = useSelector((state) => state.userDownload);
  const { error, loading, download } = userDownload;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  const { name, slug, file } = useParams();

  // useEffects
  useEffect(() => {
    dispatch(makeDownload(slug, file));
  }, [dispatch, slug, file]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      {loading ? (
        <Loader />
      ) : error ? (
        <Row className="message-contain">
          <Col md={12}>
            <Message variant="danger">{error}</Message>{" "}
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col md={12}>
              <h1 className="header">Download</h1>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className="main-text">
                <a href={download.url}>Download {name}</a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={9}>
              <p className="main-text pt-3">
                This download link will expire in 1 hour. Refresh page to
                generate a new link.
              </p>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default Download;
