import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CheckoutSummary from "./CheckoutSummary";
import CheckoutSteps from "./CheckoutSteps";
import CheckoutStepsLic from "./CheckoutStepsLic";
import PayPalSubmit from "./PayPalSubmit";
import { v4 as uuidv4 } from "uuid";
import { createIntentOrder } from "../../actions/orderActions";

function PaymentPayPal() {
  // Selectors
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const cartVoucherValid = useSelector((state) => state.cartVoucherValid);
  const { voucherValid } = cartVoucherValid;

  const cartTax = useSelector((state) => state.cartTax);
  const { tax } = cartTax;

  const cartBillingInfo = useSelector((state) => state.cartBillingInfo);
  const { billingInfo } = cartBillingInfo;

  const cartLicenseHolder = useSelector((state) => state.cartLicenseHolder);
  const { licenseHolder } = cartLicenseHolder;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const intentUuid = uuidv4();
  const [voucherUse, setVoucherUse] = useState(voucherValid.code);
  const [voucherRate, setVoucherRate] = useState(voucherValid.discount);

  // Cart Totals
  let cartSub = cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  let cartVoucherRate = parseFloat(voucherValid.discount);
  let cartVoucherTotal = (
    parseFloat(cartSub) * parseFloat(cartVoucherRate)
  ).toFixed(2);
  cartSub = (parseFloat(cartSub) - parseFloat(cartVoucherTotal)).toFixed(2);
  let cartTaxTotal;
  let cartTaxPercent;
  cartTaxTotal = (parseFloat(cartSub) * parseFloat(tax.vatRate)).toFixed(2);
  cartTaxPercent = parseFloat(100 * parseFloat(tax.vatRate));
  const cartTotal = (parseFloat(cartSub) + parseFloat(cartTaxTotal)).toFixed(2);

  // Cart Totals to Cart
  cart.order_type = "purchase";
  cart.subtotal = cartSub;
  cart.vat_rate = parseFloat(cartTaxPercent / 100);
  cart.tax = cartTaxTotal;
  cart.total = cartTotal;

  // useEffects
  useEffect(() => {
    if (!licenseHolder.name) {
      navigate("/checkout/license-holder", { replace: true });
    }
  }, [licenseHolder.name, navigate]);

  useEffect(() => {
    if (voucherUse === "") {
      setVoucherUse("");
      setVoucherRate(voucherRate);
    }
  }, [voucherUse, voucherRate]);

  useEffect(() => {
    dispatch(
      createIntentOrder({
        orderRef: intentUuid,
        orderItems: cart.cartItems,
        billingInfo: billingInfo,
        licenseHolder: licenseHolder,
        method: "PayPal",
        paypalId: "",
        stripeId: "",
        order_type: cart.order_type,
        voucherUse: voucherUse,
        voucherRate: voucherRate,
        voucherAmount: cartVoucherTotal,
        subtotal: cart.subtotal,
        vatRate: cart.vat_rate,
        tax: cart.tax,
        total: cart.total,
      })
    );
  }, [
    dispatch,
    intentUuid,
    cart,
    billingInfo,
    licenseHolder,
    voucherUse,
    voucherRate,
    cartVoucherTotal,
  ]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        {licenseHolder.licensee ? (
          <CheckoutStepsLic step1 step2 step3 step4 />
        ) : (
          <CheckoutSteps step1 step2 step3 />
        )}
        <Row>
          <Col lg={12}>
            <h1 className="header">Payment</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="header-text d-lg-none">
            <CheckoutSummary />
          </Col>
          <Col lg={3}>
            <Row>
              <Col lg={12}>
                <p className="subhead">Billing Info</p>
              </Col>
              <Col lg={12}>{billingInfo.name}</Col>
              <Col lg={12}>{billingInfo.company}</Col>
              <Col lg={12}>{billingInfo.addressOne}</Col>
              <Col lg={12}>{billingInfo.addressTwo}</Col>
              <Col lg={12}>
                {billingInfo.city}, {billingInfo.stateProvince}
              </Col>
              <Col lg={12}>{billingInfo.zipPostalCode}</Col>
              <Col lg={12}>{billingInfo.country}</Col>
              <Col lg={12}>{billingInfo.phone}</Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <p className="subhead">License Holder Info</p>
              </Col>
              <Col lg={12}>{licenseHolder.name}</Col>
              <Col lg={12}>{licenseHolder.company}</Col>
              <Col lg={12}>{licenseHolder.addressOne}</Col>
              <Col lg={12}>{licenseHolder.addressTwo}</Col>
              <Col lg={12}>
                {licenseHolder.city}, {licenseHolder.stateProvince}
              </Col>
              <Col lg={12}>{licenseHolder.zipPostalCode}</Col>
              <Col lg={12}>{licenseHolder.country}</Col>
              <Col lg={12}>{licenseHolder.phone}</Col>
            </Row>
            <Row>
              <Col md={12} className="mb-4 cart-form-link d-lg-none">
                By clicking the button below, you agree to the Garage Fonts EULA
              </Col>
              <Col md={12} className="cart-form-link d-lg-none">
                <PayPalSubmit cart={cart} intentUuid={intentUuid} />
              </Col>
            </Row>
          </Col>
          <Col lg={9} className="d-none d-lg-block">
            <Row>
              <Col lg={12}>
                <p className="subhead">Cart Summary</p>
              </Col>
              <Col md={12}>
                <Container className="payment-section" fluid>
                  {cartItems.map((cartItem, i) => (
                    <Card
                      key={i}
                      className={
                        i === 0
                          ? "cart-card cart-active"
                          : "cart-part-card cart-active"
                      }
                    >
                      <Card.Body>
                        <Row>
                          <Col xs={5} className="my-auto">
                            {cartItem.cartName}
                          </Col>
                          <Col xs={3} className="my-auto">
                            {cartItem.calcName}
                          </Col>
                          <Col xs={4} className="my-auto text-end">
                            $ {cartItem.price.toFixed(2)}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
                  {voucherValid && voucherValid.voucher_id !== 0 && (
                    <Card className="cart-part-card">
                      <Card.Body>
                        <Row>
                          <Col xs={8} className="my-auto">
                            Voucher {Math.floor(cartVoucherRate * 100)} %
                          </Col>
                          <Col xs={4} className="my-auto text-end">
                            &minus; $ {cartVoucherTotal}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )}
                  <Card className="cart-part-card">
                    <Card.Body>
                      <Row>
                        <Col xs={8} className="my-auto">
                          Subtotal
                        </Col>
                        <Col xs={4} className="my-auto text-end">
                          $ {cartSub}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="cart-part-card">
                    <Card.Body>
                      <Row>
                        <Col xs={8} className="my-auto">
                          Tax {cartTaxPercent} %
                        </Col>
                        <Col xs={4} className="my-auto text-end">
                          $ {cartTaxTotal}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="cart-part-card">
                    <Card.Body>
                      <Row>
                        <Col xs={8} className="my-auto">
                          Total
                        </Col>
                        <Col xs={4} className="my-auto text-end">
                          $ {cartTotal}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-4 cart-form-link">
                By clicking the button below, you agree to the{" "}
                <Link to="/eula">GarageFonts EULA</Link>.
              </Col>
              <Col md={12} className="cart-form-link">
                <PayPalSubmit cart={cart} intentUuid={intentUuid} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PaymentPayPal;
