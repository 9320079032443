import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Licenses() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>Licenses - GarageFonts</title>
          <meta property="og:title" content="Licenses - GarageFonts" />
        </Helmet>
        <Row>
          <Col md={12}>
            <h1 className="header">Support</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/licenses" className="nav-link active">
                  Licenses
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/eula" className="nav-link">
                  EULA
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/tiers" className="nav-link">
                  Tiers
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/discounts" className="nav-link">
                  Discounts
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/policies" className="nav-link">
                  Policies
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="mt-4">
            <p>GENERAL INFORMATION ABOUT OUR LICENSES</p>
            <p>
              Our licenses are perpetual and do not require subscription. If you
              outgrow your current license, you can upgrade by buying the same
              license again to stack the amount of users, domains, and
              apps/ebooks, or by buying a larger license.
            </p>
            <p>-------------</p>
            <p>FONT FORMATS</p>
            <p>
              All of our fonts are provided in OTF, TTF and WOFF2 formats. You
              can install the fonts on your desktop, serve them on websites
              using CSS @font-face, or embed them in mobile apps and e-books.
              Other web formats – WOFF and EOT – are available on request.
            </p>
            <p>-------------</p>
            <p>SMALL LICENSE</p>
            <p>
              A small license grants the license holder the right to:
              <br />
              (1) Install fonts locally for one user.
              <br />
              (2) Self-host fonts for one domain, including sub domains, with a
              maximum of 50,000 unique visitors per month.
              <br />
              (3) Embed fonts in one app or e-book.
              <br />
              <br />
              desktop: 1 user
              <br />
              web: 1 domain – 50,000 unique visitors p/m
              <br />
              app/e-book: 1
            </p>
            <p>-------------</p>
            <p>MEDIUM LICENSE</p>
            <p>
              A medium license grants the licensor the right to:
              <br />
              (1) Install fonts locally for a maximum of ten users within a
              single company/organisation, not necessarily on the same
              geographical location.
              <br />
              (2) Self-host fonts for a maximum of five domains, including sub
              domains, with a maximum of 500,000 unique visitors per month (all
              domains combined).
              <br />
              (3) Embed fonts in a maximum of five apps or e-books.
              <br />
              <br />
              desktop: 10 users
              <br />
              web: 5 domains – 500,000 unique visitors p/m
              <br />
              app/e-book: 5
            </p>
            <p>-------------</p>
            <p>LARGE LICENSE</p>
            <p>
              A large license grants you the right to:
              <br />
              (1) Install fonts locally for a maximum of twenty-five users
              within a single company/organisation, not necessarily on the same
              geographical location.
              <br />
              (2) Self-host fonts for a maximum of ten domains, including sub
              domains, with a maximum of 1,000,000 unique visitors per month
              (all domains combined)
              <br />
              (3) Embed fonts in a maximum of ten apps or e-books.
              <br />
              <br />
              desktop: 25 users
              <br />
              web: 10 domains – 1,000,000 unique visitors p/m
              <br />
              app/e-book: 10
            </p>
            <p>---------</p>
            <p>TRIAL LICENSE</p>
            <p>
              Trial fonts can be used for testing purposes only. The fonts have
              a limited character set and no OpenType features. Trial licenses
              are perpetual. Use in student work is permitted, as long as the
              work is not commercial. Use on social media for personal projects
              is permitted as long as GarageFonts and the name of the typeface
              are mentioned. If you wish to use the trial fonts for any other
              purposes, you have to purchase a license for the full version.
              Trial fonts will be delivered in OTF, TTF, and WOFF2 formats.
            </p>
            <p>
              Trial fonts which support Latin have the following character set:
              <br />
              -----------------------------------------------------------------
              <br />
              abcdefghijklmnopqrstuvwxyz
              <br />
              ABCDEFGHIJKLMNOPQRSTUVWXYZ
              <br />
              0123456789.,- [space]
            </p>
            <p>---------</p>
            <p>CUSTOM LICENSE</p>
            <p>
              If none of the licenses above fit your needs, please{" "}
              <Link to="/contact">get in touch</Link> for a custom license to
              accommodate your particular preferences and requirements.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Licenses;
