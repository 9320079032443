import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";

function PipeList() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="square-contain">
      <Helmet>
        <body className="section-bg" />
      </Helmet>
      <>
        <Row>
          <Col md={12}>
            <p className="font-name">Pipeline</p>
          </Col>
          <Col md={12}>
            <div className="image-square">
              <a href="/pdfs/spec/fuqua/fuqua-specimen.pdf">
                <img
                  src="/images/spec/fuqua/section/fuqua-specimen-1.png"
                  className="img-fluid spec grid"
                  alt="Fuqua"
                />
              </a>
            </div>
          </Col>
          <Col md={12}>
            <div className="image-square">
              <a href="/pdfs/spec/clarice/clarice-specimen.pdf">
                <img
                  src="/images/spec/clarice/section/clarice-specimen-1.png"
                  className="img-fluid spec grid"
                  alt="Clarice"
                />
              </a>
            </div>
          </Col>
          <Col md={12}>
            <div className="image-square">
              <a href="/pdfs/spec/fono/fono-specimen.pdf">
                <img
                  src="/images/spec/fono/section/fono-specimen-1.png"
                  className="img-fluid spec grid"
                  alt="Fono"
                />
              </a>
            </div>
          </Col>
          <Col md={12}>
            <div className="image-square">
              <a href="/pdfs/spec/nice-weekend/nice-weekend-specimen.pdf">
                <img
                  src="/images/spec/nice-weekend/section/nice-weekend-specimen-1.png"
                  className="img-fluid spec grid"
                  alt="Nice Weekend"
                />
              </a>
            </div>
          </Col>
        </Row>
      </>
    </Container>
  );
}

export default PipeList;
