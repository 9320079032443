import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Tiers() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>Tiers - GarageFonts</title>
          <meta property="og:title" content="Tiers - GarageFonts" />
        </Helmet>
        <Row>
          <Col md={12}>
            <h1 className="header">Support</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link to="/licenses" className="nav-link">
                  Licenses
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/eula" className="nav-link">
                  EULA
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/tiers" className="nav-link active">
                  Tiers
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/discounts" className="nav-link">
                  Discounts
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/policies" className="nav-link">
                  Policies
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="mt-4">
            <p>FONT TIERS</p>
            <p>
              We have designated two “font tiers” as an organizational tool as
              newer and older designs emerge and re-emerge in the catalog.
            </p>
            <p>Double Nickles:</p>
            <p>
              Double Nickel fonts feature extended character sets that are
              &#8805; the{" "}
              <a
                href="https://adobe-type-tools.github.io/adobe-latin-charsets/adobe-latin-3.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Adobe Latin 3
              </a>{" "}
              specification and contain more design precision, in terms of
              spacing, kerning, and/or features. The name references the
              Minutemen, a band from San Pedro, California who played a brand of
              punk rock that was multifaceted and precise.
            </p>
            <p>12XUs:</p>
            <p>
              12XU fonts feature basic character sets that are &#8804; the{" "}
              <a
                href="https://adobe-type-tools.github.io/adobe-latin-charsets/adobe-latin-1.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Adobe Latin 1
              </a>{" "}
              specification and contain more design experimentation, in terms of
              outlines, spacing, kerning, and/or features. The name is in
              reference to Wire, a band from London, England who played a brand
              of punk rock that was sparse and edgy.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Tiers;
