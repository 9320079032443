import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { clientTrial } from "../../actions/sectionActions";
import Loader from "../main/Loader";
import Message from "../main/Message";

function ClientTrial() {
  // Selectors
  const secClientTrial = useSelector((state) => state.secClientTrial);
  const { error, loading, success } = secClientTrial;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const location = useLocation();
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/trial-fonts/download";

  // useEffects
  useEffect(() => {
    if (success) {
      navigate(redirect, { replace: true });
    }
  }, [navigate, success, redirect]);

  // Submit
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(clientTrial(name, email, company, newsletter));
  };

  // Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Newsletter Check
  const onNewsletterCheck = () => {
    const updatedNewsletter = !newsletter;
    setNewsletter(updatedNewsletter);
  };

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top client-trial-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top client-trial-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain client-trial-bg";
  }, []);

  return (
    <Container className="section-contain client-trial-bg">
      <Helmet>
        <body className="client-trial-bg" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          {error && (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="danger">{error}</Message>
              </Col>
            </Row>
          )}
          <Form onSubmit={submitHandler}>
            <Row>
              <Col md={7} className="mb-2">
                <h1 className="header">Trial Fonts</h1>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={1}>
                <img
                  src="/images/trial/fuqua-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/fuqua-variable-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/archive-matrix-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/omnium-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/omnium-wide-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/omnium-tagline-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/arbuckle-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={1}>
                <img
                  src="/images/trial/clarice-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/district-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/homage-script-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/homage-condensed-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/archive-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/metroflex-narrow-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/metroflex-uni-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={1}>
                <img
                  src="/images/trial/metroflex-wide-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/adore-mono-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/modus-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/fono-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/nice-weekend-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/dotted-weekend-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
              <Col md={1}>
                <img
                  src="/images/trial/rough-weekend-001.png"
                  className="img-fluid"
                  alt="font-square"
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={7}>
                <p>
                  Submit your details below and you'll receive a link to
                  download our trial fonts. Trial fonts which support Latin have
                  the following character set and no OpenType features.
                </p>
                <p>
                  abcdefghijklmnopqrstuvwxyz
                  <br />
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  <br />
                  0123456789.,- [space]
                  <br />
                </p>
                <p>Please read:</p>
                <Button variant="light" onClick={handleShow}>
                  <i className="bi bi-box-arrow-up-right"></i>
                  &nbsp;GarageFonts License Agreement
                </Button>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={6}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="company"
                    placeholder=""
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" id="formGridCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="I accept the GarageFonts License Agreement"
                    autoComplete="off"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" id="formGridCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Subscribe to our newsletter (optional)"
                    autoComplete="off"
                    onChange={onNewsletterCheck}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="form-link">
                <Button type="submit" variant="primary">
                  Get the trial fonts
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>GarageFonts EULA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <p>GARAGEFONTS LLC</p>
          <p>
            End User License Agreement
            <br />
            version 1.0 — February 2023
          </p>
          <p>
            This End User License Agreement ("EULA”) is a legal contract between
            you ("You” or "Your”) and GarageFonts LLC ("GarageFonts”). When you
            purchase fonts and related software and documentation ("Font” or
            "Fonts”) from GarageFonts, You are purchasing a license ("License”)
            which governs your usage of the Fonts under terms and conditions
            that are defined and outlined in this EULA.
          </p>
          <p>
            YOU SHOULD CAREFULLY READ THE FOLLOWING TERMS AND CONDITIONS BEFORE
            USING THE FONTS. BY DOWNLOADING, INSTALLING, AND/OR USING THE FONTS
            YOU ARE CONSENTING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS
            AGREEMENT.
          </p>
          <p>---------</p>
          <p>1. GRANT OF LICENSE</p>
          <p>
            Subject to payment of applicable License fees and Your compliance
            with this EULA, GarageFonts grants You the rights that correspond to
            the License You purchased or obtained (see sections 2 and 3). All
            Licenses are non-exclusive, revocable, non-assignable, and
            non-transferable. You may not use the Fonts in any way not expressly
            permitted in this EULA.
          </p>
          <p>---------</p>
          <p>2. DEFINITIONS</p>
          <p>
            The following are definitions of Font uses that are granted to
            varying degrees by Your purchase of a Small, Medium, or Large
            License or by You obtaining a Custom License with special pricing
            requirements. It is prohibited to use the Fonts in any of the ways
            described in this section without first purchasing or obtaining a
            corresponding License (see section 3).
          </p>
          <p>
            2.1 Desktop. Fonts are installed on a local computer file system and
            are used on that computer or on output devices for printing.
            Licensing is based on the number of users, not the number of
            devices.
          </p>
          <p>
            2.2 Web. Fonts are integrated into a website as web fonts in WOFF2
            format via the CSS @font-face rule and self-hosting. Licensing is
            based on the number of domains and unique monthly website visitors.
          </p>
          <p>2.3 App/E-book. Fonts are embedded in an App and/or E-book.</p>
          <p>
            2.4 Broadcast. Fonts are used in cinema, TV streaming services,
            broadcast productions on social media sites, digital billboards and
            advertising, and/or transportation entertainment systems. Examples
            are trailers, commercials, feature films, TV series, and/or
            documentaries made for commercial or non-commercial purposes.
          </p>
          <p>
            2.5 Server. Fonts are installed on a server that is accessed by
            remote users or website visitors.
          </p>
          <p>
            2.6 Letter Set. Fonts are used in the creation of physical products
            that are sold in the form of sets of letters such as rub-on letter
            sets, rubber stamp sets, or stencil alphabet sets.
          </p>
          <p>---------</p>
          <p>3. LICENSE MODELS</p>
          <p>
            3.1 Small License. A Small License grants You the right to install
            Fonts locally for 1 user; integrate Fonts for 1 domain, including
            sub domains, with a maximum of 50,000 unique visitors per month;
            embed Fonts in 1 app or e-book.
          </p>
          <p>
            a. Desktop: 1 user
            <br />
            b. Web: 1 domain – 50,000 unique visitors per month
            <br />
            c. App/E-book: 1
          </p>
          <p>
            3.2 Medium License. A Medium License grants You the right to install
            Fonts locally for a maximum of 10 users; integrate Fonts for a
            maximum of 5 domains, including sub domains, with a maximum of
            500,000 unique visitors per month (all domains combined); embed
            Fonts in a maximum of 5 apps or e-books.
          </p>
          <p>
            a. Desktop: 10 users
            <br />
            b. Web: 5 domains – 500,000 unique visitors per month
            <br />
            c. App/E-book: 5
          </p>
          <p>
            3.3 Large License. A Large License grants You the right to install
            Fonts locally for a maximum of 25 users; integrate Fonts for a
            maximum of 10 domains, including sub domains, with a maximum of
            1,000,000 unique visitors per month (all domains combined); embed
            Fonts in a maximum of 10 apps or e-books.
          </p>
          <p>
            a. Desktop: 25 users
            <br />
            b. Web: 10 domains – 500,000 unique visitors per month
            <br />
            c. App/E-book: 10
          </p>
          <p>
            3.4 Trial License. A Trial License grants You the right to use the
            Fonts in the form of Trial Fonts. Trial Fonts have a limited
            character set and no OpenType features. Trial Fonts can be used for
            testing and trial purposes only, which may include paid and unpaid
            pitches. You are not allowed to use the Fonts in any files that are
            destined for final delivery for any project, regardless of whether
            these projects are for commercial or non-commercial purposes. If You
            are actively enrolled as a student at an accredited learning
            institution, then use of the Trial Fonts in final files is permitted
            as long as the nature of the usage is of strictly non-commercial and
            educational nature. If You wish to use the Trial Fonts for any other
            purposes, You need to purchase a Small, Medium, Large, or Custom
            License.
          </p>
          <p>
            3.5 Custom License. Custom Licenses can be obtained by contacting
            GarageFonts directly via email or phone. The following are Font uses
            that may be granted by purchasing a Custom License with special
            pricing requirements:
          </p>
          <p>
            a. Broadcast
            <br />
            b. Server
            <br />
            c. Letter Set
            <br />
            d. Uses not covered by Small, Medium, or Large Licenses
          </p>
          <p>---------</p>
          <p>4. RESTRICTIONS ON USE</p>
          <p>You are restricted from using the Fonts in the following ways:</p>
          <p>
            a. You may not share, distribute, copy, sublicense, lease, or
            transfer the Fonts. You may supply a copy of the Fonts to a service
            provider such as a graphic designer, printer, or web developer, who
            is working for You in the scope of a defined project. This service
            provider must agree to use the Fonts exclusively for this defined
            project and must not keep copies of the Fonts after the project has
            been completed. Other than these service providers working on Your
            behalf, the Fonts should not be accessible to third parties.
          </p>
          <p>
            b. You may not modify, rename or convert the Fonts under any
            circumstance or alter the source code of the Fonts.
          </p>
          <p>
            c. You may not use the Fonts to create a derivative or modified
            product or design, including creating characters for languages not
            covered by the Fonts or designing a custom version of the Fonts.
          </p>
          <p>
            d. You may not translate, reverse engineer, decompile, or
            disassemble the Fonts or assist someone in performing such acts.
          </p>
          <p>
            e. You may not serve remote access to the Fonts via company servers
            or cloud solutions unless You purchased a Custom License that grants
            such rights.
          </p>
          <p>
            f. You may not upload the Fonts to any public server or public GIT
            repository available to users outside Your organization or entity.
          </p>
          <p>
            g. You may modify the outlines of the Fonts, for example in
            Illustrator, for usage in a logo.
          </p>
          <p>---------</p>
          <p>5. NO WARRANTY</p>
          <p>
            GARAGEFONTS MAKES NO WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT OF THIRD PARTIES’
            RIGHTS. THE FONTS ARE PROVIDED TO YOU ON AN “AS IS” BASIS. TO THE
            FULL EXTENT PERMITTED BY LAW, THE DURATION OF STATUTORILY REQUIRED
            WARRANTIES, IF ANY, SHALL BE LIMITED TO THE ABOVE LIMITED WARRANTY
            PERIOD. MOREOVER, IN NO EVENT WILL WARRANTIES PROVIDED BY LAW, IF
            ANY, APPLY UNLESS THEY ARE REQUIRED TO APPLY BY STATUTE
            NOTWITHSTANDING THEIR EXCLUSION BY CONTRACT. NO DEALER, AGENT, OR
            EMPLOYEE OF GARAGEFONTS IS AUTHORIZED TO MAKE ANY MODIFICATIONS,
            EXTENSIONS, OR ADDITIONS TO THIS LIMITED WARRANTY. THE ENTIRE RISK
            ARISING OUT OF USE OR PERFORMANCE OF THE FONTS REMAINS WITH YOU.
            GARAGEFONTS WILL ATTEMPT TO MAKE KNOWN THE STATE OF ANY FONTS AT THE
            TIME OF SALE, BUT IS NOT REQUIRED TO FIX OR ADDRESS ANY TECHNICAL
            FLAWS IN THE FONTS.
          </p>
          <p>---------</p>
          <p>6. OWNERSHIP AND RIGHTS</p>
          <p>
            You agree that GarageFonts, or the designers with whom GarageFonts
            has a license agreement, own all right, title and interest in and to
            the Fonts, its structure, organization, code, and related files,
            including all intellectual property rights therein such as
            copyright, design, and trademarks rights. No right, title, or
            interest in any Fonts is granted to You by this EULA. This EULA only
            grants You the rights to use the Fonts according to the License You
            purchased or obtained.
          </p>
          <p>---------</p>
          <p>7. NO ASSIGNMENT; NO TRANSFER</p>
          <p>
            You agree not to transfer or assign the Fonts, this EULA, or any
            rights or obligations granted to You by this EULA without the prior
            written consent of GarageFonts.
          </p>
          <p>---------</p>
          <p>8. TERMINATION</p>
          <p>
            In the event that there is a material breach of this EULA by You,
            GarageFonts has the right to terminate Your license immediately.
            Upon the termination of the EULA, You must destroy all copies of the
            Fonts. If GarageFonts incurs legal fees or costs to enforce this
            provision or any other provision of this EULA, You shall be
            responsible for such fees and costs, including, without limitation,
            GarageFonts’s reasonable attorneys’ fees.
          </p>
          <p>---------</p>
          <p>9. LIMITED LIABILITY</p>
          <p>
            UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY SHALL GARAGEFONTS,
            BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY INDIRECT, SPECIAL,
            INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER INCLUDING,
            WITHOUT LIMITATION, DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS,
            BUSINESS INTERRUPTIONS, WORK STOPPAGE, COMPUTER FAILURE OR
            MALFUNCTION, OR ANY AND ALL OTHER PERSONAL OR COMMERCIAL DAMAGES OR
            LOSSES ARISING FORM THE USE OF INABILITY TO USE THE FONTS (WHETHER
            OR NOT DUE TO ANY DEFECTS THEREIN). IN NO EVENT WILL GARAGEFONTS BE
            LIABLE FOR ANY DAMAGES EVEN IF GARAGEFONTS SHALL HAVE BEEN INFORMED
            OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES WERE REASONABLY
            FORESEEABLE, OR FOR ANY CLAIM BY ANY OTHER PARTY. IN NO EVENT SHALL
            GARAGEFONTS’ LIABILITY EXCEED THE PURCHASE PRICE PAID BY YOU FOR THE
            FONTS.
          </p>
          <p>---------</p>
          <p>10. INDEMNIFICATION</p>
          <p>
            You agree to defend, indemnify, and hold harmless GarageFonts from
            and against liabilities, costs, damages, and expenses (including
            settlement costs and reasonable attorneys’ fees) arising from any
            claims from anybody that result from or relate to your use,
            reproduction, or distribution of the Fonts.
          </p>
          <p>---------</p>
          <p>11. NO AGENCY</p>
          <p>
            Nothing contained in this EULA and no activity by either party in
            the performance hereof shall constitute, create, or be deemed to
            constitute or create between either party, or between or among
            either party and any of its officers, directors, employees or
            agents, an agency or representative relationship or a partnership,
            joint venture or association. Nothing in this EULA shall be
            construed to give any Party the power or authority to act for, bind,
            or commit the other Party, unless approved in writing by both
            parties.
          </p>
          <p>---------</p>
          <p>12. ENTIRE AGREEMENT</p>
          <p>
            You acknowledge and agree that this EULA is the complete and
            exclusive statement of the agreement between you and GarageFonts
            which supersedes all proposals or prior agreements, oral or written,
            and all other communications between you and GarageFonts relating to
            the subject matter of this EULA. This EULA may only be modified by a
            written agreement signed by both you and an authorized
            representative of GarageFonts.
          </p>
          <p>---------</p>
          <p>13. SEVERABILITY</p>
          <p>
            If any term or part of a term of this EULA is invalid, illegal, or
            unenforceable, the rest of the EULA will remain in effect.
          </p>
          <p>---------</p>
          <p>14. GOVERNING LAW</p>
          <p>
            This EULA will be governed by and construed in accordance with the
            laws of the state of Pennsylvania, without regard to conflict of
            laws principles. Any claim arising out of this EULA, including tort
            claims, must be resolved in Allegheny County, Pennsylvania.
          </p>
          <p>---------</p>
          <p>15. ACKNOWLEDGMENT</p>
          <p>
            By downloading, installing, or using any part of the Fonts, you
            indicate that you have read this EULA, understand it, and agree to
            be bound by its terms and conditions.
          </p>
          <p>---------</p>
          <p>16. FORCE MAJEURE</p>
          <p>
            GarageFonts shall not be liable hereunder for any failure or delay
            in the performance of its obligations under this EULA if such
            failure or delay is on account of causes beyond its control,
            including labor disputes, civil commotion, war, fires, floods,
            inclement weather, governmental regulations or controls, casualty,
            government authority, strikes, or acts of God, in which event
            GarageFonts shall be excused from its obligations for the period of
            the delay and for a reasonable time thereafter.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ClientTrial;
