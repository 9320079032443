import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

function ArbuckleDetail() {
  //Color Scheme
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  return (
    <>
      <Helmet>
        <body className="section-bg" />
      </Helmet>
      <Container fluid className="fonts-contain">
        <>
          <Container>
            <Helmet>
              <title>{`Arbuckle - GarageFonts`}</title>
            </Helmet>
            <FadeIn>
              <Row>
                <Col md={8}>
                  <p className="detail-name">Arbuckle</p>
                  <p className="desc-status">
                    Arbuckle is certainly one of the earlier "bubble font"
                    designs, having been originally released in 2001. It was
                    designed by{" "}
                    <a
                      href="https://www.woodardworks.com/56.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Robby Woodard
                    </a>{" "}
                    and was inspired by the lettering in light-hearted comic
                    books such as Garfield. Bubbly fonts are popular at the
                    moment, considering recent releases such as Ohno Type's
                    Softie and Tipastype's Sandhouse, to name two buoyant
                    designs. Arbuckle fits great in the genre, and has the
                    additional bonus of being a layer font with a robust
                    character set. Arbuckle's layering capabilities give the
                    user the ability to control the color of the contours and
                    highlights of the letterforms. Be it packaging for bubble
                    gum, kids books about pets or clouds, ice cream
                    advertisements, you name it, Arbuckle blows a big bubble!
                  </p>
                  <p className="desc-status">
                    <a href="/font/arbuckle">Type tester and specimens</a>
                  </p>
                  <p className="desc-status">
                    <a href="/details">Back to details</a>
                  </p>
                </Col>
              </Row>
            </FadeIn>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5">
              <Col md={4} className="fonts-image-col">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/arbuckle/arbuckle-story-1.png"
                      className="img-fluid"
                      alt="detail-1"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/arbuckle/arbuckle-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/arbuckle/arbuckle-story-3.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/arbuckle/arbuckle-story-4.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/arbuckle/arbuckle-story-5.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/arbuckle" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/arbuckle/arbuckle-story-6.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </>
      </Container>
    </>
  );
}

export default ArbuckleDetail;
