import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckoutSteps from "./CheckoutSteps";

function OrderError() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      <CheckoutSteps step4 />
      <Row>
        <Col lg={12}>
          <h1 className="header">Order Error</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Link to="/account/profile">
            Sorry there was a problem with your order.
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default OrderError;
