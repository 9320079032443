import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function CatalogDetails() {
  // Footer
  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain";
  }, []);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>Catalog Details - GarageFonts</title>
          <meta property="og:title" content="About - GarageFonts" />
        </Helmet>
        <Row>
          <Col lg={7} className="mt-4">
            <p>Current:</p>
            <p>
              <a
                href="https://fonts.adobe.com/fonts/omnium"
                target="_blank"
                rel="noreferrer"
              >
                Omnium
              </a>
            </p>
            <p>
              <a
                href="https://fonts.adobe.com/fonts/omnium-tagline"
                target="_blank"
                rel="noreferrer"
              >
                Omnium Tagline
              </a>
            </p>
            <p>
              <a
                href="https://fonts.adobe.com/fonts/district"
                target="_blank"
                rel="noreferrer"
              >
                District
              </a>
            </p>
          </Col>
          <Col lg={7} className="mt-4">
            <p>Priorities:</p>
            <p>
              Fuqua Variable:&nbsp;
              <Link to="/font/fuqua-variable" className="info-nav">
                Specimen
              </Link>
              &nbsp;/&nbsp;
              <Link to="/detail/fuqua-variable" className="info-nav">
                In Use
              </Link>
            </p>
            <p>
              Arbuckle:&nbsp;
              <Link to="/font/arbuckle" className="info-nav">
                Specimen
              </Link>
              &nbsp;/&nbsp;
              <Link to="/detail/arbuckle" className="info-nav">
                In Use
              </Link>
            </p>
            <p>
              Homage Condensed:&nbsp;
              <Link to="/font/homage-condensed" className="info-nav">
                Specimen
              </Link>
              &nbsp;/&nbsp;
              <Link to="/detail/homage-condensed" className="info-nav">
                In Use
              </Link>
            </p>
            <p>
              Homage Script:&nbsp;
              <Link to="/font/homage-script" className="info-nav">
                Specimen
              </Link>
              &nbsp;/&nbsp;
              <Link to="/detail/homage-script" className="info-nav">
                In Use
              </Link>
            </p>
            <p>
              Adore Mono:&nbsp;
              <Link to="/font/adore-mono" className="info-nav">
                Specimen
              </Link>
              &nbsp;/&nbsp;
              <Link to="/detail/adore-mono" className="info-nav">
                In Use
              </Link>
            </p>
          </Col>
          <Col lg={7} className="mt-4">
            <p>Pipeline:</p>
            <p>
              All:&nbsp;
              <Link to="/detail/pipeline" className="info-nav">
                In Use
              </Link>
            </p>
            <p>----------------</p>
            <p>
              Archive:&nbsp;
              <Link to="/font/archive" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Clarice:&nbsp;
              <Link to="/font/clarice" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Dotted Weekend:&nbsp;
              <Link to="/font/dotted-weekend" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Fono:&nbsp;
              <Link to="/font/fono" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Kamaro:&nbsp;
              <Link to="/font/kamaro" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Karazan:&nbsp;
              <Link to="/font/karazan" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Kienan:&nbsp;
              <Link to="/font/kienan" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Kllf:&nbsp;
              <Link to="/font/klif" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Nice Weekend:&nbsp;
              <Link to="/font/nice-weekend" className="info-nav">
                Specimen
              </Link>
            </p>
            <p>
              Rough Weekend:&nbsp;
              <Link to="/font/rough-weekend" className="info-nav">
                Specimen
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CatalogDetails;
