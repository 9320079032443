import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { homeListReducer } from "./reducers/homeReducers";
import {
  gridListReducer,
  specListReducer,
  catalogListReducer,
  secClientTrialReducer,
  clientDownloadReducer,
} from "./reducers/sectionReducers";
import { fontListReducer, fontDetailReducer } from "./reducers/fontReducers";
import {
  collectionListReducer,
  collectionDetailReducer,
} from "./reducers/collectionReducers";
import { calcListReducer } from "./reducers/calcReducers";
import {
  cartReducer,
  cartTaxReducer,
  cartDiscountReducer,
  cartVoucherValidReducer,
  cartVatReducer,
  cartCountryReducer,
  cartUsaStateReducer,
  cartBillingInfoReducer,
  cartLicenseHolderReducer,
  cartPaymentMethodReducer,
} from "./reducers/cartReducers";
import {
  orderIntentReducer,
  orderTrialReducer,
  orderPayPalReducer,
  orderDetailsReducer,
  orderProfileOrdersReducer,
  orderProfilePursReducer,
  orderProfileTrialsReducer,
  orderProfileAddsReducer,
} from "./reducers/orderReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userActivateReducer,
  userResetSubmitReducer,
  userResetReducer,
  userDetailsReducer,
  userDownloadReducer,
  userUpdateDetailsReducer,
  userUpdatePasswordReducer,
} from "./reducers/userReducers";
import { mgmtOrderReducer } from "./reducers/mgmtReducers";

const reducer = combineReducers({
  homeList: homeListReducer,
  gridList: gridListReducer,
  specList: specListReducer,
  catalogList: catalogListReducer,
  secClientTrial: secClientTrialReducer,
  clientDownload: clientDownloadReducer,
  fontList: fontListReducer,
  fontDetail: fontDetailReducer,
  collectionList: collectionListReducer,
  collectionDetail: collectionDetailReducer,
  calcList: calcListReducer,
  cart: cartReducer,
  cartTax: cartTaxReducer,
  cartDiscount: cartDiscountReducer,
  cartVoucherValid: cartVoucherValidReducer,
  cartVat: cartVatReducer,
  cartCountry: cartCountryReducer,
  cartUsaState: cartUsaStateReducer,
  cartBillingInfo: cartBillingInfoReducer,
  cartLicenseHolder: cartLicenseHolderReducer,
  cartPaymentMethod: cartPaymentMethodReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userActivate: userActivateReducer,
  userResetSubmit: userResetSubmitReducer,
  userReset: userResetReducer,
  userDetails: userDetailsReducer,
  userDownload: userDownloadReducer,
  userUpdateDetails: userUpdateDetailsReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  orderIntent: orderIntentReducer,
  orderTrial: orderTrialReducer,
  orderPayPal: orderPayPalReducer,
  orderDetails: orderDetailsReducer,
  orderProfileOrders: orderProfileOrdersReducer,
  orderProfilePurs: orderProfilePursReducer,
  orderProfileTrials: orderProfileTrialsReducer,
  orderProfileAdds: orderProfileAddsReducer,
  mgmtOrder: mgmtOrderReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const cartTaxFromStorage = localStorage.getItem("tax")
  ? JSON.parse(localStorage.getItem("tax"))
  : {};

const cartDiscountFromStorage = localStorage.getItem("discount")
  ? JSON.parse(localStorage.getItem("discount"))
  : {};

const voucherValidFromStorage = localStorage.getItem("voucherValid")
  ? JSON.parse(localStorage.getItem("voucherValid"))
  : {};

const cartVatFromStorage = localStorage.getItem("vat")
  ? JSON.parse(localStorage.getItem("vat"))
  : {};

const cartCountryFromStorage = localStorage.getItem("countries")
  ? JSON.parse(localStorage.getItem("countries"))
  : {};

const cartUsaStateFromStorage = localStorage.getItem("usaStates")
  ? JSON.parse(localStorage.getItem("usaStates"))
  : {};

const billingInfoFromStorage = localStorage.getItem("billingInfo")
  ? JSON.parse(localStorage.getItem("billingInfo"))
  : {};

const licenseHolderFromStorage = localStorage.getItem("licenseHolder")
  ? JSON.parse(localStorage.getItem("licenseHolder"))
  : {};

const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
  ? JSON.parse(localStorage.getItem("paymentMethod"))
  : null;

const initialState = {
  cart: { cartItems: cartItemsFromStorage },
  cartTax: { tax: cartTaxFromStorage },
  cartDiscount: { discount: cartDiscountFromStorage },
  cartVoucherValid: { voucherValid: voucherValidFromStorage },
  cartVat: { vat: cartVatFromStorage },
  cartCountry: { countries: cartCountryFromStorage },
  cartUsaState: { usaStates: cartUsaStateFromStorage },
  cartBillingInfo: { billingInfo: billingInfoFromStorage },
  cartLicenseHolder: { licenseHolder: licenseHolderFromStorage },
  cartPaymentMethod: { paymentMethod: paymentMethodFromStorage },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
