import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Blog0001() {
  // Footer
  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain";
  }, []);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Row>
          <Col md={12}>
            <h1 className="header">A GarageFonts Transition</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <p>01/14/2023</p>
            <p>
              In this initial entry in our blog, we are writing to introduce
              ourselves and to let you know that GarageFonts is starting a new
              chapter. I am Jon Young (
              <a href="https://www.tiotype.com/" class="blog">
                Tiotype
              </a>
              ), the new owner of GarageFonts as of June 2022. Our headquarters
              is currently based in Pittsburgh, Pennsylvania.
            </p>
            <p>
              To begin, we'd like to say a few words about Ralph Smith, previous
              owner of the foundry. In 1999, Ralph's font distribution company
              Phil's Fonts purchased GarageFonts from Betsy Kopshina and Norbert
              Shultz, the original founders of the foundry. Under Ralph's
              leadership, GarageFonts experienced 23 years of creative
              innovation and steady growth.
            </p>
            <p>
              Sadly, Ralph passed away in Silver Spring MD, on October 4, 2022.
              I had met Ralph in 2003, when he hired me to work with him and he
              became a great mentor. Without his wisdom, training, and advice I
              would not have been involved in the font world to the degree that
              I am today. Ralph ran Phil's Fonts and GarageFonts with an equal
              measure of energy, passion, humor, and determination that will
              always be an inspiration.
            </p>
            <p>
              Going forward, the new GarageFonts aims to be a steward of the
              library and to build on its legacy by releasing new typefaces that
              are relevant, significant, purposive, and beautiful.
            </p>
            <p>—Jon</p>
            <p>---------</p>
            <p>
              <Link to="/blog">Back to GF Blog</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Blog0001;
