export const ORDER_TRIAL_INTENT_REQUEST = "ORDER_TRIAL_INTENT_REQUEST";
export const ORDER_TRIAL_INTENT_SUCCESS = "ORDER_TRIAL_INTENT_SUCCESS";
export const ORDER_TRIAL_INTENT_FAIL = "ORDER_TRIAL_INTENT_FAIL";
export const ORDER_TRIAL_INTENT_RESET = "ORDER_TRIAL_INTENT_RESET";
export const ORDER_INTENT_REQUEST = "ORDER_INTENT_REQUEST";
export const ORDER_INTENT_SUCCESS = "ORDER_INTENT_SUCCESS";
export const ORDER_INTENT_FAIL = "ORDER_INTENT_FAIL";
export const ORDER_INTENT_RESET = "ORDER_INTENT_RESET";
export const ORDER_TRIAL_REQUEST = "ORDER_TRIAL_REQUEST";
export const ORDER_TRIAL_SUCCESS = "ORDER_TRIAL_SUCCESS";
export const ORDER_TRIAL_FAIL = "ORDER_TRIAL_FAIL";
export const ORDER_TRIAL_RESET = "ORDER_TRIAL_RESET";
export const ORDER_PAYPAL_REQUEST = "ORDER_PAYPAL_REQUEST";
export const ORDER_PAYPAL_SUCCESS = "ORDER_PAYPAL_SUCCESS";
export const ORDER_PAYPAL_FAIL = "ORDER_PAYPAL_FAIL";
export const ORDER_PAYPAL_RESET = "ORDER_PAYPAL_RESET";
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_PROFILE_ORDERS_REQUEST = "ORDER_PROFILE_ORDERS_REQUEST";
export const ORDER_PROFILE_ORDERS_SUCCESS = "ORDER_PROFILE_ORDERS_SUCCESS";
export const ORDER_PROFILE_ORDERS_FAIL = "ORDER_PROFILE_ORDERS_FAIL";
export const ORDER_PROFILE_ORDERS_RESET = "ORDER_PROFILE_ORDERS_RESET";
export const ORDER_PROFILE_PURS_REQUEST = "ORDER_PROFILE_PURS_REQUEST";
export const ORDER_PROFILE_PURS_SUCCESS = "ORDER_PROFILE_PURS_SUCCESS";
export const ORDER_PROFILE_PURS_FAIL = "ORDER_PROFILE_PURS_FAIL";
export const ORDER_PROFILE_PURS_RESET = "ORDER_PROFILE_PURS_RESET";
export const ORDER_PROFILE_TRIALS_REQUEST = "ORDER_PROFILE_TRIALS_REQUEST";
export const ORDER_PROFILE_TRIALS_SUCCESS = "ORDER_PROFILE_TRIALS_SUCCESS";
export const ORDER_PROFILE_TRIALS_FAIL = "ORDER_PROFILE_TRIALS_FAIL";
export const ORDER_PROFILE_TRIALS_RESET = "ORDER_PROFILE_TRIALS_RESET";
export const ORDER_PROFILE_ADDS_REQUEST = "ORDER_PROFILE_ADDS_REQUEST";
export const ORDER_PROFILE_ADDS_SUCCESS = "ORDER_PROFILE_ADDS_SUCCESS";
export const ORDER_PROFILE_ADDS_FAIL = "ORDER_PROFILE_ADDS_FAIL";
export const ORDER_PROFILE_ADDS_RESET = "ORDER_PROFILE_ADDS_RESET";
