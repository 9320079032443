import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Blog() {
  // Footer
  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain";
  }, []);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        <Helmet>
          <body className="section-bg" />
          <title>Blog - GarageFonts</title>
          <meta property="og:title" content="Blog - GarageFonts" />
        </Helmet>
        <Row>
          <Col md={12}>
            <h1 className="header">The GF Blog</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            01/14/2023:{" "}
            <Link to="/blog/a-garagefonts-transition">
              A GarageFonts Transition
            </Link>
          </Col>
          <Col lg={12}>
            04/18/2023: <Link to="/blog/font-tiers">Font Tiers</Link>
          </Col>
          {/* <Col lg={12}>
            11/13/2023: <Link to="/blog/fuqua-release">Fuqua Release</Link>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default Blog;
