import {
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
  COLLECTION_LIST_FAIL,
  COLLECTION_DETAIL_REQUEST,
  COLLECTION_DETAIL_SUCCESS,
  COLLECTION_DETAIL_FAIL,
} from "../constants/collectionConstants";

export const collectionListReducer = (state = { collections: [] }, action) => {
  switch (action.type) {
    case COLLECTION_LIST_REQUEST:
      return { loading: true, collections: [] };
    case COLLECTION_LIST_SUCCESS:
      return { loading: false, collections: action.payload };
    case COLLECTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const collectionDetailReducer = (
  state = {
    collection: {
      fonts: [],
    },
  },
  action
) => {
  switch (action.type) {
    case COLLECTION_DETAIL_REQUEST:
      return { loading: true, ...state };
    case COLLECTION_DETAIL_SUCCESS:
      return { loading: false, collection: action.payload };
    case COLLECTION_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
