import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listFonts } from "../../actions/fontActions";
import { Container, Offcanvas } from "react-bootstrap";

function Footer() {
  // Selectors
  const fontList = useSelector((state) => state.fontList);
  const { fonts } = fontList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  // Off Canvas
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());
  const [showTypeList, setShowTypeList] = useState(false);
  const handleCloseTypeList = () => setShowTypeList(false);
  const toggleShowTypeList = () => setShowTypeList((s) => !s);
  const backdropShowTypeList = false;
  const bodyScrollTypeList = true;
  const [showSocList, setShowSocList] = useState(false);
  const handleCloseSocList = () => setShowSocList(false);
  const toggleShowSocList = () => setShowSocList((s) => !s);
  const backdropShowSocList = false;
  const bodyScrollSocList = true;

  // useEffects
  useEffect(() => {
    getYear();
  }, []);

  useEffect(() => {
    dispatch(listFonts());
  }, [dispatch]);

  return (
    <div className="d-print-none">
      <Container fluid className="footer-contain">
        <div className="text-center">
          <button
            type="button"
            className="btn btn-link"
            onClick={toggleShowTypeList}
          >
            The fonts
          </button>
          <button
            type="button"
            className="btn btn-link ms-4"
            onClick={toggleShowSocList}
          >
            @garagefonts
          </button>
          <Offcanvas
            show={showTypeList}
            onHide={handleCloseTypeList}
            backdrop={backdropShowTypeList}
            scroll={bodyScrollTypeList}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>The fonts</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul className="nav-overflow-list">
                {fonts.map((font, i) => (
                  <li key={i} onClick={handleCloseTypeList}>
                    <a href={"/font/" + font.slug}>{font.name}</a>
                  </li>
                ))}
              </ul>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas
            show={showSocList}
            onHide={handleCloseSocList}
            backdrop={backdropShowSocList}
            scroll={bodyScrollSocList}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>@garagefonts</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul className="nav-overflow-list">
                <li onClick={handleCloseSocList}>
                  <a
                    href="https://www.instagram.com/garagefonts/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li onClick={handleCloseSocList}>
                  <a
                    href="https://typo.social/@garagefonts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mastodon
                  </a>
                </li>
                <li onClick={handleCloseSocList}>
                  <a
                    href="https://github.com/garagefonts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </a>
                </li>
              </ul>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="footer-info text-center">
          <p>(C) {date} GarageFonts – all rights reserved</p>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
