import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import { logout, getUserDetails } from "../../actions/userActions";
import {
  getProfileOrders,
  getProfilePurchases,
  getProfileTrials,
  getProfileAdditions,
} from "../../actions/orderActions";
import { createGlobalStyle } from "styled-components";
import Loader from "../main/Loader";
import Message from "../main/Message";

// Global Styles
const PurchaseFont = createGlobalStyle`.${(props) =>
  props.purchase.font_class}{font-family: "${(props) =>
  props.purchase.name}";}`;

const TrialFont = createGlobalStyle`.${(props) =>
  props.trial.font_class}{font-family: "${(props) => props.trial.name}";}`;

const AdditionFont = createGlobalStyle`.${(props) =>
  props.addition.font_class}{font-family: "${(props) =>
  props.addition.name}";}`;

function Profile() {
  // Selectors
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const orderProfileOrders = useSelector((state) => state.orderProfileOrders);
  const {
    error: errorOrders,
    loading: loadingOrders,
    orders,
  } = orderProfileOrders;

  const orderProfilePurs = useSelector((state) => state.orderProfilePurs);
  const {
    error: errorPurchases,
    loading: loadingPurchases,
    purchases,
  } = orderProfilePurs;

  const orderProfileTrials = useSelector((state) => state.orderProfileTrials);
  const {
    error: errorTrials,
    loading: loadingTrials,
    trials,
  } = orderProfileTrials;

  const orderProfileAdds = useSelector((state) => state.orderProfileAdds);
  const {
    error: errorAdditions,
    loading: loadingAdditions,
    additions,
  } = orderProfileAdds;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const logoutHandler = () => {
    dispatch(logout());
  };

  // useEffects
  useEffect(() => {
    if (!userInfo) {
      navigate("/login", { replace: true });
    } else {
      if (!user || !user.name) {
        dispatch(getUserDetails("profile"));
        dispatch(getProfileOrders());
        dispatch(getProfilePurchases());
        dispatch(getProfileTrials());
        dispatch(getProfileAdditions());
      } else {
        setName(user.name);
        setEmail(user.email);
        dispatch(getProfileOrders());
        dispatch(getProfilePurchases());
        dispatch(getProfileTrials());
        dispatch(getProfileAdditions());
      }
    }
  }, [dispatch, navigate, userInfo, user]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      {loading ? (
        <Loader />
      ) : error ? (
        <>
          <Row className="message-contain">
            <Col md={12}>
              <Message variant="danger">
                We are unable to access your account.
              </Message>
            </Col>
            <Col>
              <button
                type="button"
                className="btn btn-link"
                onClick={logoutHandler}
              >
                Please log in again
              </button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <div className={orders.length > 0 ? "account-header" : ""}>
            <Row>
              <Col md={12}>
                <h1 className="header">Your account</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <p>Name: {name}</p>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <p>Email: {email}</p>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="form-link">
                <Link to="/account/change-details">Change account details</Link>
                <br />
                <Link to="/account/change-password">Change password</Link>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="form-link">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={logoutHandler}
                >
                  Log Out
                </button>
              </Col>
            </Row>
          </div>
          {loadingPurchases ? (
            <Loader />
          ) : errorPurchases ? (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="danger">{errorPurchases}</Message>
              </Col>
            </Row>
          ) : (
            <>
              {purchases.length > 0 && (
                <div className="account-section">
                  <p className="subhead">Your purchases</p>
                  {purchases.map((purchase, i) => (
                    <div key={i}>
                      <PurchaseFont purchase={purchase} />
                      <Card
                        className={
                          i === 0 ? "account-card" : "account-part-card"
                        }
                      >
                        <Card.Body>
                          <Row>
                            <Col lg={6} className="my-auto account-font-name">
                              {purchase.cart_name}
                            </Col>
                            <Col lg={2} className="my-auto">
                              {purchase.license}
                            </Col>
                            <Col lg={2} className="my-auto">
                              {purchase.ux_date}
                            </Col>
                            <Col
                              lg={2}
                              className="my-auto text-end d-none d-lg-block"
                            >
                              <a
                                href={
                                  "/account/download/" +
                                  purchase.name +
                                  "/" +
                                  purchase.f_slug +
                                  "/" +
                                  purchase.download
                                }
                              >
                                Download
                              </a>
                            </Col>
                            <Col lg={2} className="my-auto d-lg-none mt-2">
                              <a
                                href={
                                  "/account/download/" +
                                  purchase.name +
                                  "/" +
                                  purchase.f_slug +
                                  "/" +
                                  purchase.download
                                }
                              >
                                Download
                              </a>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {loadingTrials ? (
            <Loader />
          ) : errorTrials ? (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="danger">{errorTrials}</Message>
              </Col>
            </Row>
          ) : (
            <>
              {trials.length > 0 && (
                <div className="account-section">
                  <p className="subhead">Your trial licenses</p>
                  {trials.map((trial, i) => (
                    <div key={i}>
                      <TrialFont trial={trial} />
                      <Card
                        className={
                          i === 0 ? "account-card" : "account-part-card"
                        }
                      >
                        <Card.Body>
                          <Row>
                            <Col lg={6} className="my-auto account-font-name">
                              {trial.cart_name}
                            </Col>
                            <Col lg={2} className="my-auto">
                              {trial.license}
                            </Col>
                            <Col lg={2} className="my-auto">
                              {trial.ux_date}
                            </Col>
                            <Col
                              lg={2}
                              className="my-auto text-end d-none d-lg-block"
                            >
                              <a
                                href={
                                  "/account/download/" +
                                  trial.name +
                                  "/" +
                                  trial.f_slug +
                                  "/" +
                                  trial.download
                                }
                              >
                                Download
                              </a>
                            </Col>
                            <Col lg={2} className="my-auto d-lg-none mt-2">
                              <a
                                href={
                                  "/account/download/" +
                                  trial.name +
                                  "/" +
                                  trial.f_slug +
                                  "/" +
                                  trial.download
                                }
                              >
                                Download
                              </a>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {loadingAdditions ? (
            <Loader />
          ) : errorAdditions ? (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="danger">{errorAdditions}</Message>
              </Col>
            </Row>
          ) : (
            <>
              {additions.length > 0 && (
                <div className="account-section">
                  <p className="subhead">Your font additions</p>
                  {additions.map((addition, i) => (
                    <div key={i}>
                      <AdditionFont addition={addition} />
                      <Card
                        className={
                          i === 0 ? "account-card" : "account-part-card"
                        }
                      >
                        <Card.Body>
                          <Row>
                            <Col lg={6} className="my-auto account-font-name">
                              {addition.cart_name}
                            </Col>
                            <Col lg={2} className="my-auto">
                              {addition.license}
                            </Col>
                            <Col lg={2} className="my-auto">
                              {addition.ux_date}
                            </Col>
                            <Col
                              lg={2}
                              className="my-auto text-end d-none d-lg-block"
                            >
                              <a
                                href={
                                  "/account/download/" +
                                  addition.name +
                                  "/" +
                                  addition.f_slug +
                                  "/" +
                                  addition.download
                                }
                              >
                                Download
                              </a>
                            </Col>
                            <Col lg={2} className="my-auto d-lg-none mt-2">
                              <a
                                href={
                                  "/account/download/" +
                                  addition.name +
                                  "/" +
                                  addition.f_slug +
                                  "/" +
                                  addition.download
                                }
                              >
                                Download
                              </a>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {loadingOrders ? (
            <Loader />
          ) : errorOrders ? (
            <Row className="message-contain">
              <Col md={12}>
                <Message variant="danger">{errorOrders}</Message>
              </Col>
            </Row>
          ) : (
            <>
              {orders.length > 0 && (
                <div className="account-section">
                  <p className="subhead">Your invoices</p>
                  {orders.map((order, i) => (
                    <div key={i}>
                      <Card
                        className={
                          i === 0 ? "account-card" : "account-part-card"
                        }
                      >
                        <Card.Body>
                          <Row>
                            <Col lg={6} className="my-auto">
                              Invoice # {order.order_ref}
                            </Col>
                            <Col lg={2} className="my-auto">
                              $ {order.total}
                            </Col>
                            <Col lg={2} className="my-auto">
                              {order.ux_date}
                            </Col>
                            <Col
                              lg={2}
                              className="my-auto text-end d-none d-lg-block"
                            >
                              <Link
                                to={"/account/invoice/" + order.order_id + "/"}
                              >
                                View
                              </Link>
                            </Col>
                            <Col lg={2} className="my-auto d-lg-none mt-2">
                              <Link
                                to={"/account/invoice/" + order.order_id + "/"}
                              >
                                View
                              </Link>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default Profile;
