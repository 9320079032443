import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

function ResetComplete() {
  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container className="section-contain">
      <Row>
        <Col lg={12}>
          <h1 className="header">Reset Complete</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <p>Your password has been updated.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetComplete;
