import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { savePaymentMethod } from "../../actions/cartActions";
import CheckoutSummary from "./CheckoutSummary";
import CheckoutSteps from "./CheckoutSteps";
import CheckoutStepsLic from "./CheckoutStepsLic";

function PaymentMethod() {
  // Selectors
  const cartLicenseHolder = useSelector((state) => state.cartLicenseHolder);
  const { licenseHolder } = cartLicenseHolder;

  const cartPaymentMethod = useSelector((state) => state.cartPaymentMethod);
  const { paymentMethod } = cartPaymentMethod;

  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State Etc
  const [methodType, setMethodType] = useState(paymentMethod);

  // useEffects
  useEffect(() => {
    if (!licenseHolder.name) {
      navigate("/checkout/license-holder", { replace: true });
    }
  }, [licenseHolder.name, navigate]);

  useEffect(() => {
    if (!paymentMethod) {
      setMethodType("PayPal");
    }
  }, [paymentMethod]);

  // Submit
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(methodType));
    if (methodType === "PayPal") {
      navigate("/checkout/payment-paypal", { replace: true });
    } else {
      navigate("/checkout/payment-cc", { replace: true });
    }
  };

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <>
      <Container className="section-contain">
        {licenseHolder.licensee ? (
          <CheckoutStepsLic step1 step2 step3 />
        ) : (
          <CheckoutSteps step1 step2 />
        )}
        <Form onSubmit={submitHandler}>
          <Row>
            <Col lg={12}>
              <h1 className="header">Select payment method</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="header-text">
              <CheckoutSummary />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Check
                      type="radio"
                      label="Pay by PayPal"
                      id="paypal-payment"
                      name="paymentMethod"
                      value="PayPal"
                      checked={methodType === "PayPal"}
                      onChange={(e) => setMethodType(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Pay by credit card"
                      id="stripe-payment"
                      name="paymentMethod"
                      value="Stripe"
                      checked={methodType === "Stripe"}
                      onChange={(e) => setMethodType(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="eula-section">
            <Col md={12} className="cart-form-link">
              <Button type="submit" variant="primary">
                Continue
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}

export default PaymentMethod;
