import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
  COLLECTION_LIST_FAIL,
  COLLECTION_DETAIL_REQUEST,
  COLLECTION_DETAIL_SUCCESS,
  COLLECTION_DETAIL_FAIL,
} from "../constants/collectionConstants";

export const listCollections = () => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const collectionListUrl = `${endpoint}/collections/`;
    const { data } = await axios.get(collectionListUrl);

    dispatch({ type: COLLECTION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COLLECTION_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listCollectionDetail = (slug) => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_DETAIL_REQUEST });
    const endpoint = `${HOST}${API}`;
    const collectionDetailUrl = `${endpoint}/collection/${slug}`;
    const { data } = await axios.get(collectionDetailUrl);

    dispatch({ type: COLLECTION_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COLLECTION_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
