import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { listSpecs } from "../../actions/sectionActions";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../main/Loader";
import Message from "../main/Message";

function Spec() {
  // Selectors
  const specList = useSelector((state) => state.specList);
  const { error, loading, specs } = specList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);
  const randomSpecs = shuffle(specs);

  // useEffects
  useEffect(() => {
    dispatch(listSpecs());
  }, [dispatch]);

  // Navs and Footer
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain section-bg";
  }, []);

  return (
    <Container fluid className="square-contain">
      <Helmet>
        <body className="section-bg" />
        <title>Specimens - GarageFonts</title>
        <meta property="og:title" content="Specimens - GarageFonts" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : error ? (
        <Row className="message-contain">
          <Col md={12}>
            <Message variant="danger">{error}</Message>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            {randomSpecs
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((spec, i) => (
                <Col key={i} md={3}>
                  <div className="image-square">
                    <a href={"/pdfs/spec/" + spec.slug + "/" + spec.pdf}>
                      <img
                        src={
                          "/images/spec/" +
                          spec.slug +
                          "/section/" +
                          spec.source
                        }
                        className="img-fluid spec grid"
                        alt={spec.name}
                      />
                    </a>
                  </div>
                </Col>
              ))}
          </Row>
        </>
      )}
    </Container>
  );
}

export default Spec;
