import React, { useState, useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";

function Use({ font }) {
  // State Etc
  const [index, setIndex] = useState(0);
  const [uses, setUses] = useState([]);

  // useEffects
  useEffect(() => {
    setUses(
      font.uses.map((item) => ({
        ...item,
      }))
    );
  }, [font.uses]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <Container fluid id="use" className="fonts-use">
        <Row>
          <Col md={12}>
            <p className="font-section text-center">{font.name} In Use</p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-4">
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {uses
                .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                .map((use, i) => (
                  <Carousel.Item interval={2000}>
                    <img
                      src={"/images/font/" + font.slug + "/" + use.source}
                      className="img-fluid scale"
                      alt={font.slug + "-use-1"}
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Use;
